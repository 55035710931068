// @vendors
import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Helmet } from 'react-helmet'

// @components
import Layout from 'layout'
import DotsCSS from 'components/dots-css';
import Slice from './slice'

// @hooks
import useByUID from 'hooks/useByUID'

// @constants
import { HOMEPAGE } from 'constants/site'

const Historical = ({ match }) => {
    const uid = match.params.uid;
    const [prismicData, notFound] = useByUID('page', 'historical', uid, { historical: null });

    if (prismicData.page) {
        const page = prismicData.page.data;
        
        return (
            <>
                <Helmet>
                    <title>{RichText.asText(page.section_title)} - {HOMEPAGE.title}</title>
                </Helmet>
                <Layout>
                    <section className="sec__default">
                        <DotsCSS type="solid solid--r-t" color="c-gr" />
                        <DotsCSS type="solid solid--l-b" color="c-gr" />
                        <div className="container">
                            <h1 className="title__ppal title__ppal--md title__ppal--c-1 title__ppal--extra-bold title__ppal--line title__ppal--line-c-3 title__ppal--center">
                                <strong>{RichText.asText(page.section_title)}</strong>
                            </h1>
                        </div>
                    </section>
                    <section className="sec__default c-gr sec__default-top--over">
                        <div className="container">
                            <Slice 
                                slices={page.body} 
                                urlService={RichText.asText(page.service[0].url)}
                                reportService={page.report_service.length ? RichText.asText(page.report_service[0].url) : ''}
                                redirectUri={page.redirect_uri.length ? RichText.asText(page.redirect_uri) : ''}
                            />
                        </div>
                    </section>
                </Layout>
            </>
        )
    } else if (notFound) {
        return 'Not found'
    }

    return null
}

export default Historical