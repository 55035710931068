// @vendors
import { useState, useCallback, useEffect } from 'react'
import { Predicates } from 'prismic-javascript'

// @utils
import { client } from 'utils/prismic-helpers'

export default function usePrismicQueryByTags(customType, tags, fieldOrdering, getSize) {
  const [loading, setLoading] = useState(true)
  const [info, setInfo] = useState([])

  const getInfo = useCallback(async() => {
    try {
      const items = await client.query(
        [
          Predicates.at('document.type', customType),
          tags && Predicates.at('document.tags', tags)
        ],
        {
          orderings: `[my.${customType}.${fieldOrdering} desc]`,
          pageSize: getSize
        }
      )
      setInfo(items.results)
      setLoading(false)
    } catch (error) {
      console.error('Error getting blog info', error)
    }
  }, [])

  useEffect(() => {
    getInfo()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { info, loading }
}
