import ContinueProcess from "components/solution-detail/continue-process"
import Faqs from "components/solution-detail/faqs"
import ServicesAssistance from "components/solution-detail/services_assistances"
import Protects from "components/solution-detail/protects"
import Risks from "components/solution-detail/risks"
import React from 'react'

const Slice = ({ slices, code, uid }) => (
    slices.map((item, index) => {
        switch (item.slice_type) {
            case 'lo_que_protege':
                return <Protects info={item} key={item.slice_type} uid={uid} />
            case 'servicios_y_asistencia':
                return <ServicesAssistance info={item} key={item.slice_type} />
            case 'continuar_tramite':
                return <ContinueProcess info={item} index={index} key={item.slice_type} codeProduct={code} />
            case 'riesgos':
                return <Risks info={item} index={index} key={item.slice_type} uid={uid} />
            case 'faqs':
                return <Faqs info={item} key={item.slice_type} />

            default:
                return null
        }
    })
)

export default Slice