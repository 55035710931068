// @constants

export const HOMEPAGE = {
	title: 'Portal Agentes Sura México',
	meta: ''
}

export const pagConfig = {
	pageSize: 15,
	hideOnSinglePage: true,
	showSizeChanger: false,
}

export const requestReport = {
	paging: {
		pageNumber: 1,
		pageSize: 999
	},
	customFilters: [
		{
			selector: "Compare",
			data: {
				prop: "USER",
				value: "",
				equals: true,
				greaterThan: false,
				lowerThan: false,
				nonEqual: false
			}
		}
	]
}

export const MESSAGES = {
	error: {
		default: 'Ha ocurrido un error, por favor contacta al área encargada',
		accountStatus: {
			agentsError: 'En caso de ser promotor deberá de solicitar sus estados de cuenta comunicándose al área de administración de agentes',
			services: {
				dates: 'A la fecha no cuentas con estados de cuentas generados. Te invitamos a tramitar tus estados de cuenta por los métodos tradicionales.',
				documents: 'No cuentas con estados de cuentas generados. Te invitamos a tramitar tus estados de cuenta por los métodos tradicionales.'
			}
		},
		cotizacion: {
			codeProduct: "No se ha encontrado el código del producto, por favor contactarse con el área encargada"
		}
	},
	success: {
		accountStatus: {
			services: {
				documents: 'Se ha descargado correctamente el archivo'
			}
		}
	}
}