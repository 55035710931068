import React from "react";
import { Modal } from "antd";

// @styles
import "./styles.scss";

export default function ChooseOnlyUser({
  users,
  visible,
  onClose,
  selectUser,
}) {

  return (
    <Modal
      title={
        <h3 className="title__ppal title__ppal--sm title__ppal--c-dr title__ppal--line title__ppal--extra-bold title__ppal--line-c-2 title__ppal--center title__ppal--column">
          <span>Escoger </span>
          <strong>Usuario</strong>
        </h3>
      }
      visible={visible}
      onCancel={onClose}
      wrapClassName="modal__default"
      width="450px"
      footer={null}
      
      
    >
      <ul className="list-user">
      {users.length > 0 && users.map((userObj, index) => (
          <li
          onClick={() => selectUser(userObj.cdUsuario)}
          data-testid="userObj"
          key={index}
          >
          {userObj.nombreUsuario} <span>- {userObj.cdUsuario}</span>
          </li>
          ))}
          
      </ul>
    </Modal>
  );
}
