import PostDate from 'components/news/list/post/PostDate';
import React from 'react'
import { Link } from 'react-router-dom'
import { RichText } from 'prismic-reactjs'
import './styles.scss'

export default function Banner({ info }) {
    return (
        <div className="post__banner" id='novedad-detalle-image' style={{ backgroundImage: `url('${info.image.url}')` }}>
            <Link to='/novedades' className="post__banner-previous">
                <em className="fal fa-long-arrow-left" /> Volver
            </Link>
            <div className="post__banner-caption">
                <h1 className='post__banner-caption--title' id='novedad-detalle-title'>{info.title}</h1>
                <div className='post__banner-caption--description' id='novedad-detalle-description'><PostDate date={info.date} /> : {RichText.asText(info.author)}</div>
            </div>
        </div>
    )
}