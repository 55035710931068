// @vendors
import React, { useMemo, useState } from "react";
import moment from "moment";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

// @constants
import { HOMEPAGE } from "constants/site";

// @hooks
import usePrismicQuerySingle from "hooks/usePrismicQuerySingle";

// @components
import Layout from "layout";
import DotsCSS from "components/dots-css";
import Loading from "components/loading";

// @styles
import "./styles.scss";
import { Button, Card, Col, DatePicker, Row, Select } from "antd";
import {
  reportTypesOptions,
  reportFileTypesOptions,
  reportTypes,
  basesCardUrls,
} from "utils/enums";

const generateSelects = (data = []) => {
  const selects = data.map((item) => {
    const name = item?.primary?.name?.[0]?.text ?? "";
    const label = item?.primary?.label?.[0]?.text ?? "";
    const options = name === "reportType" ? reportTypesOptions : reportFileTypesOptions;
    return { name, label, options };
  });
  return selects;
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const CardManagement = () => {
  const { info, loading } = usePrismicQuerySingle("pagina_de_gestion_de_cartera");
  const { uid } = useParams();
  const [data, setData] = React.useState({ reportType: null, format: null });
  const [dateData, setDateData] = React.useState({ from: null, to: null });
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [isFocused, setIsFocused] = React.useState({ to: false, from: false });
  const [canShowError, setCanShowError] = React.useState(false);
const [url, setUrl] = useState("")
  const page = useMemo(() => info.page, [info]);

  const { title, selects } = React.useMemo(() => {
    const title = page?.title?.[0]?.text ?? "";
    const selects = generateSelects(page?.body ?? []);
    return { title, selects };
  }, [page]);

  const handleChange = (name, value) => {
    if (value !== reportTypes.cancelacionDefinitivaPorAgente && name === "reportType") {
      handleCleanDate();
    }
    setData({ ...data, [name]: value });
  };
  const handleChangeDate = (name, value) => setDateData({ ...dateData, [name]: value });

  const handleCleanDate = () => {
    setDateData({ from: null, to: null });
    setCanShowError(false);
  };
  const handleClean = () => {
    setData({ reportType: null, format: null });
    setDateData({ from: null, to: null });
    setCanShowError(false);
  };

  const isToBeforeFrom =
    dateData.to && dateData.from && moment(dateData.to).isBefore(dateData.from);
  const generateUrl = () => {
    const isCancelPerAgent = data.reportType === reportTypes.cancelacionDefinitivaPorAgente;
    const hasDateError = Object.values(dateData).some((item) => !item) || isToBeforeFrom;
    const hasError = Object.values(data).some((item) => !item);

    if (hasError || (isCancelPerAgent && hasDateError)) {
      return setCanShowError(true);
    }
    setIsLoaded(false);

    const getBaseUrl = (reportType) => {
      if (reportTypes.deudorPorPrima === reportType) {
        return basesCardUrls.deudorPorPrima;
      }
      if (reportTypes.detallePagosAplicados === reportType) {
        return basesCardUrls.detallePagosAplicados;
      }
      if (reportTypes.cancelacionDefinitivaPorAgente === reportType) {
        return basesCardUrls.cancelacionDefinitivaPorAgente;
      }
      if (reportTypes.detalleDeRemesas === reportType) {
        return basesCardUrls.detalleDeRemesas;
      }
      if (reportTypes.rechazoTarjetaCredito === reportType) {
        return basesCardUrls.rechazoTarjetaCredito;
      }
      if (reportTypes.primasEnDepositoCierre === reportType) {
        return basesCardUrls.primasEnDepositoCierre;
      }
    };
    const { reportType, format } = data;
    let url = `${getBaseUrl(reportType)}&run.outputFormat=${format}&p_P_AGENTE=${uid}`;
    if (isCancelPerAgent) url += `&p_FECHA_INI=${dateData.from}&p_FECHA_FIN=${dateData.to}`;
    const timeStamp = new Date().getTime();
    url += `&timeStamp=${timeStamp}`;
    
    setUrl(url)
    const loginWindow = window.open(
      basesCardUrls.login,
      "_blank",
      "width=1200,height=800,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no"
    );
    
    sleep(1200).then(() => {
      loginWindow.location.href = url;
      setIsLoaded(true);
    });
  };

  const generateClassname = (value, customClass) =>
    `ant-row ant-form-item ${value ? "ant-form-item-has-success" : ""} ${customClass ?? ""}`;

  if (loading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>{HOMEPAGE.title}</title>
      </Helmet>
      <Layout>
        <section className="sec__default">
          <DotsCSS type="solid solid--r-t" color="c-gr" />
          <DotsCSS type="solid solid--l-b" color="c-gr" />
          <div className="container">
            <h1 className="title__ppal title__ppal--md title__ppal--c-1 title__ppal--extra-bold title__ppal--line title__ppal--line-c-3 title__ppal--center">
              <strong>{title}</strong>
            </h1>
          </div>
        </section>
        <section className="sec__default c-gr content_section_card_management">
          <Card className="CardFilterContainerMyCard" bordered={false}>
            <Row gutter={[16, 16]}>
              {selects.map((item, index) => (
                <Col xs={24} sm={12} key={index} className={generateClassname(data[item.name])}>
                  <div className="ant-col ant-form-item-label label_select_card_management">
                    <label id={`slct-${item.name}-label`}>{item.label}</label>
                  </div>
                  <Select
                    id={`slct-${item.name}-label`}
                    onChange={(value) => handleChange(item.name, value)}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    value={data[item.name]}
                  >
                    {item.options.map((option, k) => (
                      <Select.Option value={option.value} key={k}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                  {data[item.name] === null && canShowError && (
                    <div className="alert alert-message">Debes seleccionar una opción.</div>
                  )}
                </Col>
              ))}
              {data.reportType === reportTypes.cancelacionDefinitivaPorAgente && (
                <>
                  <Col
                    xs={24}
                    sm={12}
                    className={generateClassname(
                      dateData.from,
                      isFocused.from && "date_picker_card_manager"
                    )}
                  >
                    <div className="ant-col ant-form-item-label label_select_card_management">
                      <label id={`slct-from-label`}>Fecha de Inicio</label>
                    </div>
                    <DatePicker
                      id={`slct-from-label`}
                      onChange={(_, date) => {
                        if (!date) return handleChangeDate("from", null);
                        const newDate = new Date(date + "T00:00:00");
                        handleChangeDate("from", format(new Date(newDate), "dd-MM-yyyy"));
                      }}
                      value={dateData.from ? moment(dateData.from, "DD-MM-YYYY") : null}
                      inputReadOnly
                      onFocus={() => setIsFocused({ ...isFocused, from: true })}
                      onBlur={() => setIsFocused({ ...isFocused, from: false })}
                      disabledDate={(current) => {
                        if(current > new Date()) return true
                        if (!dateData.to) return false;
                        return current > moment(dateData.to, "DD-MM-YYYY");
                      }}
                    />

                    {dateData.from === null && canShowError && (
                      <div className="alert alert-message">
                        {isToBeforeFrom
                          ? "Verifica el rango de fechas"
                          : "Debes seleccionar una opción."}
                      </div>
                    )}
                  </Col>

                  <Col
                    xs={24}
                    sm={12}
                    className={generateClassname(
                      dateData.to,
                      isFocused.to && "date_picker_card_manager"
                    )}
                  >
                    <div className="ant-col ant-form-item-label label_select_card_management">
                      <label id={`slct-to-label`}>Fecha Fin</label>
                    </div>
                    <DatePicker
                      id={`slct-to-label`}
                      onChange={(_, date) => {
                        if (!date) return handleChangeDate("to", null);
                        const newDate = new Date(date + "T00:00:00");
                        handleChangeDate("to", format(new Date(newDate), "dd-MM-yyyy"));
                      }}
                      value={dateData.to ? moment(dateData.to, "DD-MM-YYYY") : null}
                      inputReadOnly
                      onFocus={() => setIsFocused({ ...isFocused, to: true })}
                      onBlur={() => setIsFocused({ ...isFocused, to: false })}
                      disabledDate={(current) => {
                        if (!dateData.from) return false;
                        return current <= moment(dateData.from, "DD-MM-YYYY");
                      }}
                    />

                    {dateData.to === null && canShowError && (
                      <div className="alert alert-message">
                        {isToBeforeFrom
                          ? "Verifica el rango de fechas"
                          : "Debes seleccionar una opción."}
                      </div>
                    )}
                  </Col>
                </>
              )}

              <Col xs={2} />
              <Col>
                <Button
                  className="ant-btn outline c-2 search_button_card_management"
                  onClick={handleClean}
                >
                  Restablecer
                </Button>
              </Col>
              <Col>
                <Button
                  className="ant-btn solid c-2 search_button_card_management"
                  onClick={generateUrl}
                  loading={!isLoaded}
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Card>
        </section>
      </Layout>
    </>
  );
};
