import React, { useState, useEffect, useContext } from 'react';
import { Button, Select, Tabs } from 'antd';
import { RichText } from 'prismic-reactjs';
import { useHistory } from 'react-router-dom';
import { PortalAgentesContext, PortalAgentesFormContext } from 'context/context';
import { cotizacion } from 'authConfig';
import Estimate from 'components/estimate';
import Empty from 'components/empty';
import ChooseOnlyUser from 'components/choose-only-user';
import './styles.scss';

const Insurances = ({ items }) => {

	const history = useHistory();
	const {
		handle: { setSelectedUser },
		value: { user }
	} = useContext(PortalAgentesContext);
	const {
		handle: { setSelectedProduct, setHaveSubProducts, setAllowedUsers, setOptions },
		value: { selectedProduct, haveSubProducts, allowedUsers }
	} = useContext(PortalAgentesFormContext);
	const [selectedOptions, setSelectedOption] = useState({});
	const [openEstimate, setOpenEstimate] = useState(false);
	const [openChooseOnlyUser, setOpenChooseOnlyUser] = useState(false);
	const [selectFlag, setSelectFlag] = useState('');
	const [formFor, setFormFor] = useState('');
	const [products, setProducts] = useState({});
	const [tabInfo, setTabInfo] = useState({});
	const [selectValues, setSelectValues] = useState({});

	const changeSubProduct = (key, value) => {
		let values = { ...selectValues };
		values[key] = value;
		setSelectedOption({ ...selectedOptions, [key]: value });
		setSelectFlag(value);
		setSelectValues(values);
	}

	const setOpenEstimateWith = () => {
		setOpenEstimate(true);
		setFormFor(formFor);
	}

	const openChooseUser = (key, product, code) => {
		validateAllowedUsers(code);
		setFormFor(key);
		setSelectFlag(selectValues[product.product]);
		setSelectedProduct(product);
		setOpenChooseOnlyUser(true);
	}

	const validateAllowedUsers = (code) => {
		const allowed = [];
		user.assignedUsers.forEach(u => {
			u.soluciones.forEach(app => {
				if (app.clave.toString() === code) {
					allowed.push(u);
				}
			})
		});
		setAllowedUsers(allowed);
	}

	const closeChooseUser = () => {
		setFormFor('');
		setSelectedProduct({});
		setSelectedUser('');
		setHaveSubProducts(false);
		setOpenChooseOnlyUser(false);
	}

	const closeModalEstimate = () => {
		setHaveSubProducts(false);
		setSelectedProduct({});
		setOptions({});
		setOpenEstimate(false);
	}

	const selectUser = (cdUsuario) => {
		setSelectedUser(cdUsuario);
		if (haveSubProducts || (selectedProduct && selectedProduct.goCotizador)) {
			setOpenChooseOnlyUser(false);
			setOpenEstimateWith();
		} else {
			const parameters = `?portal=${selectedProduct.report}&username=${cdUsuario}&redirecturi=${selectedProduct.redirectUri}&loginType=B2CAuth&secret=${cotizacion.secret}`;
			window.open(`${cotizacion.url}${parameters}`, '_blank');
			setOpenChooseOnlyUser(false);
		}
	}

	const descriptionEvent = (path, producto, subProducts, code) => {
		validateAllowedUsers(code);
		setHaveSubProducts(subProducts);
		setSelectedProduct(producto);
		history.push(path);
	}

	const validateProducts = (usuarios) => {
		const apis = {};
		usuarios.forEach(userMap => {
			userMap.soluciones.forEach(solucion => {
				apis[solucion.clave] = apis[solucion.clave] ? [...apis[solucion.clave], userMap.cdUsuario] : [userMap.cdUsuario];
			});
		});
		setProducts(apis);
		validateTabInfo(apis);
	}

	const validateTabInfo = (apis) => {
		const objTabs = {};
		items.forEach(tab => {
			tab.items.forEach(item => {
				if (!apis[item.primary.clave[0].text]) {
					if (!objTabs[tab.title[0].text]) {
						objTabs[tab.title[0].text] = false;
					}
				} else {
					objTabs[tab.title[0].text] = true;
				}
			});
		});
		setTabInfo(objTabs);
	}

	useEffect(() => {
		if (user.assignedUsers.length) {
			validateProducts(user.assignedUsers);
		}
	}, [user.assignedUsers]);

	return (
		<>
			<Tabs defaultActiveKey="0" className="tabs__insurances" >
				{items.map((insurance, k) => (
					<Tabs.TabPane
						tab={
							<span>
								{insurance.image.url ? (
									<img src={insurance.image.url} alt={insurance.image.alt} />
								) : (
									<em className={`fal fa-${RichText.asText(insurance.icon)}`} />
								)}
								<strong>
									<span>Seguros </span>{RichText.asText(insurance.title)}
								</strong>
							</span>
						}
						key={`${insurance.title[0].text.toLowerCase()}`}
						id={`seguro-group-${k}-tab`}
						data-testid={insurance.title}
					>
						<div className="container">
							{insurance.items.map((product, j) => (
								products[product.primary.clave[0].text] ? (
									<div
										className="item__group"
										key={`${j}-itme`}
										id={`seguro-gruop-${k}-producto-${j}`}
									>
										<div className="item__group-container">
											<div className="item__group-container--name">
												{product.primary.image.url ? (
													<img src={product.primary.image.url} alt={product.primary.image.alt} />
												) : (
													<em className={`fal fa-${RichText.asText(product.primary.icon)}`} />
												)}
												<span
													id={`seguro-gruop-${k}-producto-${j}-title`}>{RichText.asText(product.primary.name)}</span>
											</div>
											{product.primary.have_subproducts ? (
												<div className="item__group-container--select">
													<Select
														placeholder="Seleccionar"
														onChange={(value) => changeSubProduct(RichText.asText(product.primary.name), value)}
														data-testid="selectOnChange"
														value={selectValues[product.primary.name]}
													>
														{product.items.map((item, x) => item.link.link_type !== 'Any' && (
															<Select.Option value={item.link.uid} key={`${x}-product`} data-testid={`option${x}`}>
																{RichText.asText(item.subproduct)}
															</Select.Option>
														))}
													</Select>
												</div>
											) : ''}
											{selectedOptions[RichText.asText(product.primary.name)] && (
												<div className="item__group-container--actions">
													<Button
														id={`seguro-gruop-${k}-producto-${j}-btn-descripcion`}
														className="ant-btn outline c-2"
														onClick={() => {
															const producto = {
																report: product.primary.report.length ? product.primary.report[0].text : '',
																claveProducto: product.primary.clave_producto[0].text,
																// idPuntoVenta: null,
																selectFlag: selectValues[product.primary.name[0].text],
																redirectUri: product.primary.redirect_uri[0].text,
																goCotizador: product.primary.go_portal_cotizador,
																urlCotizador: product.primary.url_cotizador.length ? product.primary.url_cotizador[0].text : '',
																product: product.primary.name[0].text
															}
															descriptionEvent(`/soluciones/${selectValues[product.primary.name[0].text]}`, producto, true, product.primary.clave[0].text);
														}}
													>
														Descripción
													</Button>
													<Button
														className="solid c-2"
														onClick={() => {
															const producto = {
																report: product.primary.report.length ? product.primary.report[0].text : '',
																claveProducto: product.primary.clave_producto[0].text,
																redirectUri: product.primary.redirect_uri[0].text,
																goCotizador: product.primary.go_portal_cotizador,
																urlCotizador: product.primary.url_cotizador.length ? product.primary.url_cotizador[0].text : '',
																product: product.primary.name[0].text
															}
															setHaveSubProducts(true);
															openChooseUser(product.primary.key, producto, product.primary.clave[0].text);
														}}
														id={`seguro-gruop-${k}-producto-${j}-btn-cotizar`}
													>
														Cotizar
													</Button>
												</div>
											)}
											{!product.primary.have_subproducts && (
												<div className="item__group-container--actions">
													{product.primary.link.link_type !== 'Any' ? (
														<Button
															id={`seguro-gruop-${k}-producto-${j}-btn-descripcion`}
															className="ant-btn outline c-2"
															onClick={() => {
																const producto = {
																	report: product.primary.report.length ? product.primary.report[0].text : '',
																	claveProducto: product.primary.clave_producto[0].text,
																	selectFlag: product.primary.key,
																	// idPuntoVenta: null,
																	redirectUri: product.primary.redirect_uri[0].text,
																	goCotizador: product.primary.go_portal_cotizador,
																	urlCotizador: product.primary.url_cotizador.length ? product.primary.url_cotizador[0].text : '',
																	product: product.primary.name[0].text
																}
																descriptionEvent(`/soluciones/${product.primary.link.uid}`, producto, false, product.primary.clave[0].text);
															}}
														>
															Descripción
														</Button>
													) : ''}
													<Button
														id={`seguro-gruop-${k}-producto-${j}-btn-cotizar`}
														className="solid c-2"
														onClick={() => {
															const producto = {
																report: product.primary.report.length ? product.primary.report[0].text : '',
																solucion: product.primary.clave.length ? product.primary.clave[0].text : "",
																selectFlag: product.primary.key,
																claveProducto: product.primary.clave_producto[0].text,
																// idPuntoVenta: null,
																redirectUri: product.primary.redirect_uri.length ? product.primary.redirect_uri[0].text : '',
																goCotizador: product.primary.go_portal_cotizador,
																urlCotizador: product.primary.url_cotizador.length ? product.primary.url_cotizador[0].text : '',
																product: product.primary.name[0].text
															}
															openChooseUser(product.primary.key, producto, product.primary.clave[0].text);
														}}
													>
														Cotizar
													</Button>
												</div>
											)}
										</div>
									</div>
								) : null)
							)}
							{!tabInfo[insurance.title[0].text] ? <Empty message={"El usuario no tiene productos asociados"} /> : null}
						</div>
					</Tabs.TabPane>
				))}
			</Tabs>
			<ChooseOnlyUser
				users={allowedUsers}
				visible={openChooseOnlyUser}
				onClose={() => closeChooseUser()}
				selectUser={(cdUsuario) => selectUser(cdUsuario)}
			/>
			<Estimate
				open={openEstimate}
				closeModal={() => closeModalEstimate()}
				name={formFor}
			/>
		</>
	);
}

export default Insurances;