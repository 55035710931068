import React from 'react';
import { Button, Modal } from 'antd';

const ModalMessage = ({ open, closeModal, message, width=400 }) => {

  return (
    <Modal 
      title='' 
      visible={open} 
      onCancel={() => closeModal()} 
      wrapClassName="modal__default modal__default-empty" 
      width={`${width}px`} footer=""
    >
      <div className="empty" style={{ width: 'auto' }}>
        <h2 className="empty-message" style={{ fontSize: '19px' }}>
          {message}
        </h2>
      </div>
      <Button className="solid c-3" data-testid="btn-close" onClick={() => closeModal()}>
        Aceptar
      </Button>
    </Modal>
  )
}

export default ModalMessage;