import React, {useContext} from 'react';
import {PortalAgentesContext} from 'context/context';

import {Routes} from "../App";
import Header from "./header/index";
import Footer from "./footer/index";
import ErrorPage from 'pages/errors';
import Loading from 'components/loading';

const Layout = () => {
  const {value: {user, loadingProfile}} = useContext(PortalAgentesContext);

  return (
    <>
      <Header/>
      {user.userProfile.success ? (
        <>
          <Routes userProfile={user.userProfile}/>
          <Footer/>
        </>
      ) : loadingProfile ? <Loading/>
        : <ErrorPage type="noPermissions"/>
      }
    </>
  );

}

export default Layout;