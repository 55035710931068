// @vendors
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// @constants
import { HOMEPAGE } from 'constants/site'

// @components
import Layout from 'layout'

// @styles
import './styles.scss'

const messages = {
    error: {
        type: '404',
        title: 'Página no encontrada',
        description: 'Vuelve a la página anterior o al inicio',
        button: {
            active: true,
            label: 'Volver al inicio',
            link: '/'
        }
    },
    noPermissions: {
        type: 'Ups',
        title: 'El usuario no tiene permisos',
        description: 'Por favor contacte al administrador',
        button: {
            active: false
        }
    }
}

export default function ErrorPage({ type }) {
    return (
        <>
            <Helmet>
                <title>{HOMEPAGE.title}</title>
            </Helmet>
            <Layout>
                <section className={`sec__default sec__default-error ${type === 'noPermissions' && 'full'}`}>
                    <div className="container">
                        <div className='error__page'>
                            <h2 className='error__page-title'>
                                {messages[type].type}
                            </h2>
                            <p className='error__page-parag'>
                                <strong>{messages[type].title}</strong>
                                {messages[type].description}
                            </p>
                            {/* Preguntarle a Estiven si esto se puede eliminar ya que no se está utilizando. La propiedad active no existe por fuera de button que es como tiene la validación */}
                            {messages[type].active && (
                                <Link to={`${messages[type].link}`} className='ant-btn outline c-2' data-testid="btn">
                                    {messages[type].label}
                                </Link>
                            )}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}