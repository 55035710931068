const PostDate = ({ date }) => {
    if (date !== null) {
        const day = 86400000;
        const dateFormat = {
            month: 'short',
            day: '2-digit',
            year: 'numeric'
        };
        const parser = Date.parse(date) + day;
        const displayDate = new Intl.DateTimeFormat('es-CO', dateFormat).format(new Date(parser));
    
        return displayDate
    } else {
        return null;
    }

};

export default PostDate;