import React from 'react'
import { fields } from '__test__/components/historical/form/data.mock'
import FieldSelect from './field-select'
import FieldText from './field-text'

const SliceFields = ({ fields, form }) => (

    fields ? fields.map((field, k) => {
        switch (field.type) {
            case 'Campo de texto':
                return <FieldText data={field} key={k} />

            case 'Selección única':
                return <FieldSelect data={field} key={k} form={form} />

            default:
                return null
        }
    }) : null
)

export default SliceFields;