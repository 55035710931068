// @vendors
import React, { useMemo, useContext } from 'react';
import { Button } from 'antd';
import { RichText } from 'prismic-reactjs';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { PortalAgentesFormContext } from 'context/context';

// @constants
import { HOMEPAGE } from 'constants/site';

// @hooks
import usePrismicQuerySingle from 'hooks/usePrismicQuerySingle';
import usePrismicQueryByTags from 'hooks/usePrismicQueryByTags';

// @components
import Layout from 'layout'
import DotsCSS from 'components/dots-css';
import Loading from 'components/loading';

// @styles
import './styles.scss';
import { replaceAccents } from 'utils/replace-accents';

const HistorialPage = () => {
    const history = useHistory();
    const { handle: { reset } } = useContext(PortalAgentesFormContext);
    const { info: infoHistoricals, loading: loadingHistoricals } = usePrismicQueryByTags('historical', [], '', 20)
    const { info, loading } = usePrismicQuerySingle('historicalpage');

    const page = useMemo(() => info.page, [info])
    const historicalsList = useMemo(() => infoHistoricals, [infoHistoricals]);

    const redirectTo = (path) => {
        reset();
        history.push(path);
    }

    return (
        <>
            <Helmet>
                <title>
                    Historiales - {HOMEPAGE.title}
                </title>
            </Helmet>
            <Layout>
                {loading || loadingHistoricals
                    ? <Loading />
                    : (
                        <>
                            <section className="sec__default">
                                <DotsCSS type="solid solid--r-t" color="c-gr" />
                                <DotsCSS type="solid solid--l-b" color="c-gr" />
                                <div className="container">
                                    <h1 className="title__ppal title__ppal--md title__ppal--c-1 title__ppal--extra-bold title__ppal--line title__ppal--line-c-3 title__ppal--center">
                                        <strong>{RichText.asText(page.section_title)}</strong>
                                    </h1>
                                </div>
                            </section>
                            <section className="sec__default c-gr">
                                <div className="container">
                                    <div className="list__historicals">
                                        {historicalsList.map((historical, k) => (
                                            <Button
                                                onClick={() => redirectTo(`historiales/${historical.uid}`)}
                                                key={k}
                                                className='list__historicals-item'
                                                id={`${replaceAccents(historical.data.section_title[0].text)}-btn`}
                                                data-testid='redirectTo'
                                                
                                            >
                                                {RichText.asText(historical.data.section_title)}
                                            </Button>
                                        ))}
                                    </div>
                                </div>
                            </section>
                        </>
                    )
                }
            </Layout>
        </>
    )

}

export default HistorialPage