import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Col, Form, Input } from 'antd'

const FieldText = ({ data }) => {
    return (
        <Col xs={24} sm={12}>
            <Form.Item
                name={RichText.asText(data.name).replace(/\s+/g, '-').replace(/[á]/g, 'a').replace(/[é]/g, 'e').replace(/[í]/g, 'i').replace(/[ó]/g, 'o').replace(/[ú]/g, 'u').replace(/[ñ]/g, 'n').toLowerCase()}
                rules={[{ required: data.required, message: 'Please input your username!' }]}
            >
                <Input placeholder=" " />
                <label className="ant-label">{RichText.asText(data.name)}</label>
            </Form.Item>
        </Col>
    )
}

export default FieldText