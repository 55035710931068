import FormDinamic from 'components/historical/form'
import Stats from 'components/historical/stats'
import TableDinamic from 'components/historical/table'
import React from 'react'

const Slice = ({ slices, urlService, reportService, redirectUri }) => (
    slices.map((slice, k) => {
        
        switch (slice.slice_type) {
            case 'formulario_busqueda':
                return <FormDinamic fields={slice.items} urlService={urlService} reportService={reportService} key={`${slice.slice_type}-${k}`} disabledSearchButton={true}/>
            case 'tabla':
                return <TableDinamic data={slice} key={`${slice.slice_type}-${k}`} redirectUri={redirectUri} />
            case 'estadistica':
                return <Stats data={slice} key={`${slice.slice_type}-${k}`} />
        
            default:
                return null
        }
    })
)

export default Slice