import React from 'react'
import './styles.scss'
import Image from 'assets/images/empty.png'

const Empty = ({ message }) => {
    return (
        <div className="empty">
            <img src={Image} alt="No se encuentra nada" className="empty-image" />
            <h2 className="empty-message">
                {message}
            </h2>
        </div>
    )
}

export default Empty