import React from "react";
import "./styles.scss";

export const QuestionViewer = ({ question }) => {
  const { titulo = [], contenido = [] } = question;

  if (!titulo.length || !contenido.length) return <></>;
  return (
    <div className="question_section_root">
      <h2 className="question_section_title">{titulo[0]?.text}</h2>
      {contenido.map((parrafo, index) => (
        <p key={index} className="question_section_body">
          {parrafo?.text}
        </p>
      ))}
    </div>
  );
};
