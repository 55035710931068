import React from "react";
import { Button, Card } from "antd";
import "./styles.scss";

const DownloadDocumentCard = ({ image, text, link, downloadLink }) => {
  const onDownload = () => {
    if (!downloadLink) return;
    window.open(downloadLink, "_blank");
  };
  return (
    <Card
      hoverable
      cover={<img src={image} alt={text} width={189} height={120} />}
      className="download_document_root"
    >
      <p className="title empty-message" style={!link ? { padding: "10px" } : {}}>
        {text}
      </p>

      {!!link && (
        <Button className="link-button" type="link" href={link} target="_blank">
          VER MÁS
        </Button>
      )}
      {!!downloadLink && (
        <div className="download-button" onClick={onDownload}>
          <em className="fal fa-thin fa-download" />
        </div>
      )}
    </Card>
  );
};

export default DownloadDocumentCard;
