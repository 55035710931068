import React from 'react'
import { Button, Modal, Form, Row, Col } from 'antd'
import SliceFields from '../../estimate/form/slice-fields';

const ChooseOptions = ({ open, closeModal, fields, continueModal }) => {
	const [form] = Form.useForm();


	const cleanFields = () => {
		if(fields.length > 0){
			fields.map(field => {
				const name = field.name[0]?.text?.toLowerCase()?.replace(/\s/g, '-');
				form.resetFields([name])
			})
		}
	}
	const handleCloseModal = () => {
		cleanFields()
		closeModal()
	}

	const handleSuccess = () => {
		continueModal()
		cleanFields()
	}

	return (
		<Modal
			visible={open}
			onCancel={() => handleCloseModal()}
			data-testid="modalestimate"
			wrapClassName="modal__default"
			width="600px"
			footer=""
			title={
				<h3 className="title__ppal title__ppal--sm title__ppal--c-dr title__ppal--line title__ppal--extra-bold title__ppal--line-c-2 title__ppal--center title__ppal--column">
					<span>Seleccionar</span>
					<strong>Opciones</strong>
				</h3>
			}
		>
			<Form layout='vertical' form={form}>
				<Row gutter={25}>
					<SliceFields fields={fields} form={form} />
					<Col span={24} className="form-actions">
						<Button data-testid="btn-cancelar-formdinamic" onClick={() => handleCloseModal()} className="outline c-2">
							Cancelar
						</Button>
						<Button type="primary" className="solid c-2" onClick={() => handleSuccess()} data-testid="btnCotizar">
							Continuar
						</Button>
					</Col>
				</Row>
			</Form>
		</Modal>
	)
}

export default ChooseOptions;