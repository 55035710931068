import React from 'react'
import { Link } from 'react-router-dom'
import PostDate from './PostDate'
import './styles.scss'

export default function Post({ info, index }) {
    return (
        <Link to={`novedades/${info.uid}`} className="news__list-item" id={`novedad-${index}`}>
            <div className="news__list-item--image">
                <img src={info.data.image.url} alt={info.data.image.alt} id={`novedad-${index}-image`} />
            </div>
            <div className="news__list-item--caption">
                <div className="news__list-item--caption-category" id={`novedad-${index}-category`}>
                    {info.data.category}
                </div>
                <div className="news__list-item--caption-date" id={`novedad-${index}-date`}>
                    <PostDate date={info.data.date} />
                </div>
                <div className="news__list-item--caption-title" id={`novedad-${index}-title`}>
                    {info.data.title}
                </div>
            </div>
        </Link>
    )
}