// @vendors
import React from "react";
import { Link } from "react-router-dom";
import { RichText } from "prismic-reactjs";
import { List, Tooltip } from "antd";

// @components
import Loading from "components/loading";

// @styles
import "./styles.scss";

// @utils
import { replaceAccents } from "utils/replace-accents";

const Documents = ({ info, docs, loadingDocs }) => {
  const data = info.primary;
  const documents = React.useMemo(() => {
    const items = docs?.page?.body?.[0]?.items ?? [];

    if (!items.length) return [];

    return items.map((item) => ({
      text: item?.descripcion_del_documento?.[0]?.text,
      link: item?.redireccion?.url,
      downloadLink: item?.documento?.url,
      image: item?.imagen_del_documento?.url,
    }));
  }, [docs]);

  return (
    <div className="cards__container-item" id={replaceAccents(RichText.asText(data.section_title))}>
      <div className="cards__container-item--header" id="documentos-de-interes-title">
        <em className={`fal fa-${RichText.asText(data.section_icon)}`} />
        <span>{RichText.asText(data.section_title)}</span>
      </div>
      <div className="cards__container-item--body">
        <div className="docs__container">
          {loadingDocs ? (
            <Loading />
          ) : (
            <>
              <List
                dataSource={documents}
                id="documentos-de-interes-documents-list"
                renderItem={(doc, k) => (
                  <li className={`documentos-de-interes-documents-list-item-${k}`}>
                    <img
                      src={doc.image}
                      alt={doc.text}
                      className="documentos_de_interes_image_homepage"
                    />
                    <span>{doc.text}</span>
                    <div className="button_container_document_homepage">
                      {doc.downloadLink && (
                        <Tooltip title="Descargar" placement="right">
                          <a
                            href={doc.downloadLink}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <em className="fal fa-download" />
                          </a>
                        </Tooltip>
                      )}
                      {doc.link && (
                        <Tooltip title="Abrir" placement="right">
                          <a href={doc.link} target="_blank" rel="noopener noreferrer">
                            <em className="fal fa-link" />
                          </a>
                        </Tooltip>
                      )}
                    </div>
                  </li>
                )}
              />
              {docs.length === 10 && (
                <Link
                  to="/documentos-importantes"
                  className="ant-btn outline c-2"
                  id="documentos-de-interes-see-all-documents"
                >
                  Ver todos
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Documents;
