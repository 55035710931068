import React from 'react'
import { RichText } from 'prismic-reactjs'
import { replaceAccents } from 'utils/replace-accents';

const Indicators = ({ info }) => {
    const data = info.primary;
    return (
        <div className="cards__container-item" id={replaceAccents(RichText.asText(data.section_title))}>
            <div className="cards__container-item--header" id={`${replaceAccents(RichText.asText(data.section_title))}-title`}>
                <em className={`fal fa-${RichText.asText(data.section_icon)}`} />
                <span>
                    {RichText.asText(data.section_title)}
                </span>
            </div>
            <div className="cards__container-item--body">
                Indicadores
            </div>
        </div>
    )
}

export default Indicators