// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = process.env.REACT_APP_PRISMIC_URL;

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = process.env.REACT_APP_PRISMIC_TOKEN;


// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
    if(doc.type === 'solutions') return `/soluciones`
    if(doc.type === 'services') return `/servicios`
    if(doc.type === 'news') return `/novedades`
    if(doc.type === 'documents') return `/documentos`
    if(doc.type === 'historicalpage') return `/historiales`
    if(doc.type === 'historical') return `/historiales/${doc.uid}`
    if(doc.type === 'new_post') return `/novedades/${doc.uid}`
    if(doc.type === 'solution_detail') return `/soluciones/${doc.uid}`
    if(doc.type === 'account_status') return `/estados-cuenta`
    if(doc.type === 'call_center') return `/call-center`
    if(doc.type === 'pagina_de_documentos_de_interes') return `/documentos-importantes`
    return '/'
}
