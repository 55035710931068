import React, { useEffect, useContext, useState } from "react";
import { RichText } from "prismic-reactjs";
import { Col, Form, Select } from "antd";
import {
  PortalAgentesContext,
  PortalAgentesFormContext,
} from "context/context";
import { Microservice } from "services/microservice";

// @utils
import { replaceAccents } from "utils/replace-accents";
import { useMsal } from "@azure/msal-react";
import ModalMessage from "components/modal-message";

const FieldSelect = ({ data, form }) => {
  const {
    value: { user },
  } = useContext(PortalAgentesContext);
  const {
    handle: {
      setSelectedProduct,
      setDisabledFieldsEstimateForm,
      setOptions,
      setDisabledQuotationButton,
    },
    value: { selectedProduct, disabledFieldsEstimateForm, options },
  } = useContext(PortalAgentesFormContext);
  const [listName, setListName] = useState("");
  const { instance, accounts } = useMsal();
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [messageModal, setMessageModal] = useState("");

  const fieldsKeys = ["puntoVenta", "subgrupo", "perfil-de-tarifa", "agentes"];

  const getParameters = (param) => {
    switch (param) {
      case "cd-usuario":
        return user.selectedUser;
      case "cd-ramo":
        return selectedProduct.claveProducto;
      case "cd-punto-venta":
        return selectedProduct.puntoVenta;
      case "cd-subgrupo":
        return selectedProduct.subgrupo;
      default:
        return "";
    }
  };

  const getOptions = async (urlService, nameList) => {
    const body = {
      method: "GET",
      url: `${urlService}`,
      body: {},
    };
    try {
      const copyOptions = { ...options };
      setOptions({});
      const res = await Microservice(
        body,
        user.idToken,
        "management",
        instance,
        accounts
      );
      if (res !== null && res.status == 200) {
        if (Array.isArray(res)) {
          copyOptions[nameList] = res;
          setOptions(copyOptions);
        } else {
          copyOptions[nameList] = [];
          setOptions(copyOptions);
        }
      } else {
        copyOptions[nameList] = [];
        setOptions(copyOptions);
        setDisabledQuotationButton(true);
        openModalMessage(res.message);
      }
    } catch (error) {
      console.log(`Error en lista ${data.name[0].text} :::>`, error);
    }
  };

  const replaceParameters = (urlService) => {
    const url = new URL(urlService);
    const params = new URLSearchParams(url.search.slice(1));
    const urlBase = new URL(url.origin + url.pathname);
    for (const param of params) {
      if (param[1] === "value" || param[1] === "values") {
        urlBase.searchParams.append(param[0], getParameters(param[0]));
      } else {
        urlBase.searchParams.append(param[0], param[1]);
      }
    }
    return urlBase;
  };

  const validateFields = (key, name) => {
    const fieldEmpty = name === "subgrupo" && selectedProduct["atrSubgrupoId"];
    const differField = key != name && key != "puntoVenta";
    return { fieldEmpty, differField };
  };

  const handleChange = (name, value, tokenModel) => {
    const copySelectedProduct = { ...selectedProduct };
    copySelectedProduct[name] = value;
    options[data.key_field].forEach((x) => {
      if (x[data.prop_clave] === value) {
        if (Object.hasOwnProperty.call(x, "oficina")) {
          copySelectedProduct["oficina"] = x["oficina"].clave;
          copySelectedProduct["oficinaDescripcion"] = x["oficina"].descripcion;
        }
        if (Object.hasOwnProperty.call(x, "nombreAgente")) {
          copySelectedProduct["nombreAgente"] = x["nombreAgente"];
        }
        if (Object.hasOwnProperty.call(x, "nombrePromotor")) {
          copySelectedProduct["nombrePromotor"] = x["nombrePromotor"];
        }
        if (Object.hasOwnProperty.call(x, "promotor")) {
          copySelectedProduct["promotor"] = x["promotor"];
        }

        // logica para armar token dependiendo de como este configurado en prismic
        if (tokenModel) {
          const model = JSON.parse(tokenModel);
          const modelKeys = Object.keys(model);
          modelKeys.forEach((element) => {
            const nodes = model[element] ? model[element].split(".") : [];
            var findNode = x;
            nodes.forEach((node) => {
              if (/\[[\d]+\]/.test(node)) {
                findNode = findNode[0];
              } else {
                findNode = findNode[node];
              }
            });
            copySelectedProduct[element] = findNode;
          });
        }
      }
    });
    setSelectedProduct(copySelectedProduct);
    let list = "";
    const copy = { ...disabledFieldsEstimateForm };
    const keys = Object.keys(copy);
    keys.forEach((key, index) => {
      if (name != "agente") {
        if (
          selectedProduct[name] &&
          selectedProduct[name] != value &&
          validateFields(key, name).differField &&  name!='perfilTarifa'
        ) {
          if (copy[key].fieldController === name) {
            copy[key].disabled = true;
          }
          options[key] = null;
          copySelectedProduct[key] = null;
          form.resetFields([fieldsKeys[index]]);
        }
        if (
          (name === "perfilTarifa" &&
            !selectedProduct["agente"] &&
            key === "agente") ||
          (options[name].length === 1 &&
            name === "subgrupo" &&
            validateFields(key, name).differField)
        ) {
          copy[key].disabled = true;
        }
        if (
          validateFields(key, name).fieldEmpty &&
          validateFields(key, name).differField
        ) {
          copy[key].disabled = true;
        }
      }
      if (copy[key].fieldController === name && copy[key].disabled) {
        copy[key].disabled = false;
        list = key;
      }
    });
    setListName(list);
    setDisabledFieldsEstimateForm(copy);
  };

  const closeModalEstimate = () => {
    setShowModalMessage(false);
    setMessageModal("");
  };

  const openModalMessage = (msg) => {
    setMessageModal(msg);
    setShowModalMessage(true);
  };

  const configFields = () => {
    const config = {
      disabled: data.dependency,
      fieldController: data.field_dependency.length
        ? data.field_dependency[0].text
        : "",
      url: data.url_service.length ? data.url_service[0].text : "",
    };
    disabledFieldsEstimateForm[data.key_field] = config;
    setDisabledFieldsEstimateForm(disabledFieldsEstimateForm);
  };

  useEffect(() => {
    if (data && data.key_field && options) {
      const nameList = data.key_field;
      if (options[nameList] && options[nameList].length === 1) {
        const tokenModel =
          data.tokenmodel && data.tokenmodel.length
            ? data.tokenmodel[0].text
            : null;
        const optionValue =
          options[nameList][0].clave || options[nameList][0].agente || "";
        const fieldKey = replaceAccents(RichText.asText(data.name));
        form.setFieldsValue({ [fieldKey]: optionValue });
        handleChange(nameList, optionValue, tokenModel);
      }
    }
  }, [options]);

  useEffect(() => {
    if (user.idToken.length) {
      if (data.options.length <= 1) {
        configFields();
        if (!data.dependency) {
          const url = replaceParameters(data.url_service[0].text);
          getOptions(url, data.key_field);
        }
      }
    }
  }, [data, user.idToken, user.selectedUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      disabledFieldsEstimateForm &&
      disabledFieldsEstimateForm[listName] &&
      !disabledFieldsEstimateForm[listName].dependency
    ) {
      if (
        options &&
        !options[listName] &&
        selectedProduct[disabledFieldsEstimateForm[listName].fieldController]
      ) {
        const url = replaceParameters(disabledFieldsEstimateForm[listName].url);
        getOptions(url, listName);
      }
    }
  }, [disabledFieldsEstimateForm, listName]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Col xs={24} sm={12}>
        <Form.Item
          label={RichText.asText(data.name)}
          name={replaceAccents(RichText.asText(data.name))}
        >
          <Select
            id={`slct-${replaceAccents(RichText.asText(data.name))}`}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(e) => {
              const tokenModel =
                data.tokenmodel && data.tokenmodel.length
                  ? data.tokenmodel[0].text
                  : null;
              handleChange(data.key_field, e, tokenModel);
            }}
            disabled={
              disabledFieldsEstimateForm &&
              disabledFieldsEstimateForm[data.key_field] &&
              options &&
              options[data.key_field] &&
              options[data.key_field].length > 1
                ? disabledFieldsEstimateForm[data.key_field].disabled
                : true
            }
          >
            {options &&
            options[data.key_field] &&
            options[data.key_field].length ? (
              <>
                {options[data.key_field].map((option, k) => (
                  <Select.Option value={option[data.prop_clave]} key={k}>
                    {`${option[data.prop_clave]}${option[data.prop_descripcion]?` - ${option[data.prop_descripcion]}`:'' }`}
                  </Select.Option>
                ))}
              </>
            ) : null}
          </Select>
        </Form.Item>
      </Col>
      <ModalMessage
        open={showModalMessage}
        closeModal={closeModalEstimate}
        message={messageModal}
      />
    </>
  );
};

export default FieldSelect;
