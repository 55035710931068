export const reportTypesOptions = [
  // { value: 1, label: "Deudor por prima" },
  // { value: 2, label: "Rechazo tarjeta crédito" },
  { value: 3, label: "Detalle de pagos aplicados" },
  { value: 4, label: "Cancelación definitiva por agente" },
  { value: 5, label: "Primas en depósito cierre" },
  { value: 6, label: "Detalle de remesas" },
];

export const reportFileTypesOptions = [
  { value: "PDF", label: "PDF" },
  { value: "spreadsheetML", label: "Excel" },
  { value: "HTML", label: "HTML" },
];

export const reportTypes = {
  deudorPorPrima: 1,
  rechazoTarjetaCredito: 2,
  detallePagosAplicados: 3,
  cancelacionDefinitivaPorAgente: 4,
  primasEnDepositoCierre: 5,
  detalleDeRemesas: 6,
};

export const basesCardUrls = {
  deudorPorPrima:
    "https://agentes.segurossura.com.mx/ibmcognos/cgi-bin/cognosisapi.dll?b_action=cognosViewer&m=portal/launch.xts&ui.tool=CognosViewer&ui.action=run&run.prompt=false&ui=h1h2h3h4&ui.header=false&ui.toolbar=false&uiname=Deudor%20por%20Prima&ui.object=/content/folder[@name=%27Produccion%27]/folder[@name=%27Canal%20Virtual%27]/folder[@name=%27PIC%27]/report[@name=%2701%20SP%20Deudor%20por%20Prima%27]",
  rechazoTarjetaCredito:
    "https://agentes.segurossura.com.mx/ibmcognos/cgi-bin/cognosisapi.dll?b_action=cognosViewer&m=portal/launch.xts&ui.tool=CognosViewer&ui.action=run&run.prompt=false&ui=h1h2h3h4&ui.header=false&ui.toolbar=false&uiname=Rechazo%20Tarjeta%20Crédito&ui.object=/content/folder[@name=%27Produccion%27]/folder[@name=%27Canal%20Virtual%27]/folder[@name=%27PIC%27]/report[@name=%2702%20Reporte%20Rechazo%20Tarjeta%20Credito%20(Todos)%27]",
  detallePagosAplicados:
    "https://agentes.segurossura.com.mx/ibmcognos/cgi-bin/cognosisapi.dll?b_action=cognosViewer&m=portal/launch.xts&ui.tool=CognosViewer&ui.action=run&run.prompt=false&ui=h1h2h3h4&ui.header=false&ui.toolbar=false&uiname=Detalle%20de%20Pagos%20Aplicados&ui.object=/content/folder[@name=%27Produccion%27]/folder[@name=%27Canal%20Virtual%27]/folder[@name=%27PIC%27]/report[@name=%2703%20SP%20Detalle_de_Pagos_Aplicados_Por_Periodo%27]",
  cancelacionDefinitivaPorAgente:
    "https://agentes.segurossura.com.mx/ibmcognos/cgi-bin/cognosisapi.dll?b_action=cognosViewer&m=portal/launch.xts&ui.tool=CognosViewer&ui.action=run&run.prompt=false&ui=h1h2h3h4&ui.header=false&ui.toolbar=false&uiname=Cancelación%20Definitiva%20Por%20Agente&ui.object=/content/folder[@name=%27Produccion%27]/folder[@name=%27Canal%20Virtual%27]/folder[@name=%27PIC%27]/report[@name=%2704%20SP%20Cancelacion%20Definitiva%20Por%20Agente%27]",
  primasEnDepositoCierre:
    "https://agentes.segurossura.com.mx/ibmcognos/cgi-bin/cognosisapi.dll?b_action=cognosViewer&m=portal/launch.xts&ui.tool=CognosViewer&ui.action=run&run.prompt=false&ui=h1h2h3h4&ui.header=false&ui.toolbar=false&uiname=Primas%20en%20Depósito%20Cierre&ui.object=/content/folder[@name=%27Produccion%27]/folder[@name=%27Canal%20Virtual%27]/folder[@name=%27PIC%27]/report[@name=%2705%20SP%20Primas%20en%20Deposito%20Cierre%27]",
  detalleDeRemesas:
    "https://agentes.segurossura.com.mx/ibmcognos/cgi-bin/cognosisapi.dll?b_action=cognosViewer&m=portal/launch.xts&ui.tool=CognosViewer&ui.action=run&run.prompt=false&ui=h1h2h3h4&ui.header=false&ui.toolbar=false&uiname=Detalle%20de%20Remesas&ui.object=/content/folder[@name=%27Produccion%27]/folder[@name=%27Canal%20Virtual%27]/folder[@name=%27PIC%27]/report[@name=%2706%20SP%20Detalle%20de%20Remesas%27]",
  login:
    "https://agentes.segurossura.com.mx/ibmcognos/cgi-bin/cognosisapi.dll?b_action=cognosViewer&m=portal/launch.xts&ui.tool=CognosViewer&ui.action=run&CAMNamespace=ADS+Royal&CAMUsername=RCOGNOS&CAMPassword=*RsaCognos2016&h_CAM_action=logonAs",
};

export const inputsTypes = {
  range: "range",
  simpleSelect: "simpleSelect",
  text: "text",
  number: "number",
};
