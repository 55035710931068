import Documents from 'components/homepage/documents';
import Indicators from 'components/homepage/indicators';
import Offices from 'components/homepage/offices';
import React from 'react'

const Slice = ({ data, docs, loadingDocs }) => (
	data.map((item, index) => {
		switch (item.slice_type) {
			case 'oficinas':
				return <Offices info={item} key={index} />
			case 'documentos':
				return <Documents info={item} key={index} docs={docs} loadingDocs={loadingDocs} />
			case 'indicadores':
				return <Indicators info={item} key={index} />
			default:
				return null
		}
	})
);

export default Slice;