import React from 'react'
import { RichText } from 'prismic-reactjs'
import './styles.scss'

const Protects = ({ info, uid }) => {
    const imageStyle = info.primary.title[0].text !== "¡Mejora tu vida hoy! Descubre el futuro digital con Vida Grupo Digital"
    return (
        <section
            className={`sec__default sec__default-solution c-gr ${uid === 'vg' ? 'special-vg-styles' : ''}`}
        >
            <div className={`container ${uid === 'vg' ? 'container-vg' : ''}`}>
                <h2
                    id='seguro-detalle-protege-title'
                    className="title__ppal title__ppal--center title__ppal--sm title__ppal--dr title__ppal--line title__ppal--line-c-3 title__ppal--extra-bold">
                    <strong>
                        {RichText.asText(info.primary.title)}
                    </strong>
                </h2>
                <ul className="list__protects">
                    {info.items.map((item, index) => (
                        <li
                            id={`seguro-detalle-protege-item-${index}`}
                            className={`list__protects-item ${uid === 'vg' ? 'special-item-vg' : ''}`}
                            key={RichText.asText(item.title)}
                        >
                            <img
                                id={`seguro-detalle-protege-item-${index}-image`}
                                src={item.image.url}
                                alt={item.image.alt}
                                className={imageStyle ? "list__protects-item--image-full" : 'list__protects-item--image'}
                            />
                            <h3
                                className="list__protects-item--title"
                                id={`seguro-detalle-protege-item-${index}-title`}
                            >
                                {RichText.asText(item.title)}
                            </h3>
                            <p
                                className="list__protects-item--parag"
                                id={`seguro-detalle-protege-item-${index}-parag`}
                            >
                                {RichText.asText(item.description1)}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default Protects