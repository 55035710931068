import React, { useContext } from 'react';
import { RichText } from 'prismic-reactjs';
import { Col, DatePicker } from 'antd';
import { PortalAgentesFormContext } from 'context/context';
import { subDays } from 'date-fns';
import esES from 'antd/lib/calendar/locale/es_ES';
import moment from 'moment';
import 'moment/locale/es-mx';

// @utils
import { replaceAccents } from 'utils/replace-accents'
moment.locale('es-mx');

const FieldDate = ({ data }) => {
	const {
		handle: { handleInputChange, handleInputDate },
		value: { dataFields, fechas }
	} = useContext(PortalAgentesFormContext);

	function onChange(date, dateString) {
		const dateObj = { ...fechas };
		const event = { target: { name: data.key, value: dateString.length ? dateString : null } }
		dateObj[data.key] = { value: date };
		handleInputChange(event);
		handleInputDate(dateObj);
	}

	function disableDate(current) {
		const today = new Date();
		const currentYaerStartDay = new Date(today.getFullYear(), 0, 1);
		let lastYear = subDays(currentYaerStartDay, 365);
		if(data.type !== 'Fecha desde'){
			const currentYaerEndDay = new Date(today.getFullYear(), 11, 31);
			let nextYear = subDays(currentYaerEndDay, -366);
			return  current >= nextYear || current <= lastYear;
		}
		return current > today || current < lastYear;
	}

	return (
		<Col xs={24} sm={12} md={12} lg={8}>
			<div className={`ant-row ant-form-item ${dataFields[data.key] !== null ? 'ant-form-item-has-success' : ''}`}>
				<div className="ant-col ant-form-item-label">
					<label
						id={`slct-${replaceAccents(RichText.asText(data.name))}-label`}
					>
						{RichText.asText(data.name)}
					</label>
				</div>
				<DatePicker
					locale={esES}
					format="MM/DD/YYYY"
					inputReadOnly={true}
					onChange={onChange}
					value={(dataFields[data.key] !== undefined && dataFields[data.key] !== null) ?
						moment(fechas[data.key].value)
						: null
					}
					disabledDate={disableDate}
					id={`dte-${replaceAccents(RichText.asText(data.name))}`}
					data-testid="inputTextDatePicker"
					/>
			</div>
		</Col>
	)
}

export default FieldDate