import React, { useEffect, useMemo, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { Select, Tooltip } from 'antd'
import { replaceAccents } from 'utils/replace-accents';
import './styles.scss'

const Offices = ({ info }) => {
    const items = info.items;
    const data = info.primary;

    const offices = useMemo(() =>
        items.map((office) => ({
            address: office.address.length && office.address,
            campus: office.campus.length && office.campus[0].text,
            maps: office.maps.length && office.maps[0].text,
            waze: office.waze.length && office.waze[0].text,
            schedules: office.schedules.length && office.schedules,
            phones: office.phones.length && office.phones
        })), [items])

    const [categories, setCategories] = useState([])
    const [filteredOffice, setFilteredOffice] = useState(offices[0] ? offices[0] : {})
    const [selectedCategory, setSelectedCategory] = useState('');

    function getOffice(value) {
        const getPosOffice = offices.findIndex(item => item.campus === value)
        setFilteredOffice(offices[getPosOffice])
        setSelectedCategory(value)
    }

    useEffect(() => {
        if (offices.length) {
            const categoriesIndexes = []
            offices.forEach(category => {
                if (!categoriesIndexes.includes(category.campus)) {
                    categoriesIndexes.push(category.campus)
                }
            });
            setCategories(categoriesIndexes)
            setSelectedCategory(categoriesIndexes[0])
        }
    }, [offices])

    return (
        <div className="cards__container-item" id={replaceAccents(RichText.asText(data.section_title))}>
            <div className="cards__container-item--header" id={`${replaceAccents(RichText.asText(data.section_title))}-title`}>
                <em className={`fal fa-${RichText.asText(data.section_icon)}`} />
                <span>
                    {RichText.asText(data.section_title)}
                </span>
            </div>
            <div id='oficinas-en-mexico-select' className={`cards__container-item--body cards__container-item--body-actions`}>
                <Select 
                    value={selectedCategory} 
                    showSearch 
                    onChange={(value) => getOffice(value)} 
                    data-testid="select-onchange"
                    getPopupContainer={trigger => trigger.parentNode}
                >
                    {categories.map((item, k) => {
                        return (
                            <Select.Option value={item} key={k} data-testid={`option${k}`}>
                                {item}
                            </Select.Option>
                        )
                    })}
                </Select>
                {filteredOffice.campus && (
                    <div className="name" id='oficinas-en-mexico-item-name'>
                        {filteredOffice.campus}
                    </div>
                )}
                <ul className="list__data">
                    {filteredOffice.address && (
                        <li id='oficinas-en-mexico-item-address'>
                            <em className="fal fa-map-marker-alt" />
                            <span>
                                <RichText render={filteredOffice.address} />
                            </span>
                        </li>
                    )}
                    {filteredOffice.phones &&
                        <li className="simple" id='oficinas-en-mexico-item-phone'>
                            <em className="fal fa-phone" />
                            <span>
                                {filteredOffice.phones.map((item, k) => (
                                    <a href={`tel:${item.text}`} key={`numero-${k}`}>{item.text}</a>
                                ))}
                            </span>
                        </li>
                    }
                    {filteredOffice.schedules && (
                        <li id='oficinas-en-mexico-item-schedules'>
                            <em className="fal fa-clock" />
                            <span>
                                <div className="subtitle">
                                    Horarios de atención:
                                </div>
                                <RichText render={filteredOffice.schedules} />
                            </span>
                        </li>
                    )}
                </ul>
                <div className="actions" id='oficinas-en-mexico-actions'>
                    {filteredOffice.waze ? (
                        <Tooltip title="Abrir en Waze" placement="top">
                            <a
                                href={filteredOffice.waze}
                                target="_blank"
                                rel="noopener noreferrer"
                                id='oficinas-en-mexico-actions-waze'
                            >
                                <em className="fab fa-waze" />
                            </a>
                        </Tooltip>
                    ) : ''}
                    {filteredOffice.maps ? (
                        <Tooltip title="Abrir en Google Maps" placement="top">
                            <a
                                href={filteredOffice.maps}
                                target="_blank"
                                rel="noopener noreferrer"
                                id='oficinas-en-mexico-actions-maps'
                            >
                                <em className="fab fa-google" />
                            </a>
                        </Tooltip>
                    ) : ''}
                </div>
            </div>
        </div>
    )
}

export default Offices;