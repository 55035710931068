import React from "react";
import { QuestionViewer } from "components/question-viewer";
import "./styles.scss";

export const Questions = ({ info }) => {
  if (!info) return <></>;

  const { items: questions } = info;

  return (
    <div className="questions_container_root">
      <h1 className="questions_container_title">¿Tienes alguna duda?</h1>
      {questions.map((question, index) => (
        <QuestionViewer key={index} question={question} />
      ))}
    </div>
  );
};
