import React from 'react'
import { RichText } from 'prismic-reactjs'

export default function Video({ data, index }) {
    function switchVideo(type) {
        if (type === 'video_youtube') {
            return `https://www.youtube.com/embed/${RichText.asText(data.primary.yt_code)}`
        } else {
            return `https://player.vimeo.com/video/${RichText.asText(data.primary.vm_code)}`
        }
    }
    return (
        <div className="post__video" id={`novedad-detalle-video-${index}`}>
            <iframe src={switchVideo(data.slice_type)} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    )
}