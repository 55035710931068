import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import * as serviceWorker from './serviceWorker';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, resetPassword } from "./authConfig";
import { EventType } from '@azure/msal-browser';
// import 'index.css';

let msalInstance = null;
const msalConfigSelected = msalConfig;
msalInstance = new PublicClientApplication(msalConfigSelected);

const id = msalInstance.addEventCallback((message) => {
  if (message.eventType === EventType.LOGIN_FAILURE){
    if (message.error.errorMessage.includes("AADB2C90118")) { 
      const authority = resetPassword.url;
      const msalConf = {...msalConfigSelected.auth};
      msalConf.authority = authority;
      msalInstance.loginRedirect(msalConf);
    }
  }
});

ReactDOM.render(
    <App pca={msalInstance}/>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
