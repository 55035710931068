import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Select } from "antd";
import { PortalAgentesContext } from "context/context";
import { Microservice } from "services/microservice";
import { useMsal } from "@azure/msal-react";
import usePrismicQuerySingle from "hooks/usePrismicQuerySingle";

const Alert = ({ message = "", show = false }) => {
  if (!show) return <></>;

  return (
    <div className="alert-info" style={{ minWidth: "100%" }}>
      <em className="fal fa-info-circle" />
      <div>
        <p style={{ fontWeight: "600" }}>Estimado agente:</p>
        <p>{message}</p>
      </div>
    </div>
  );
};

export const ModalAgentSelector = ({ visible, onClose }) => {
  const { info } = usePrismicQuerySingle("modal_datos_generales");
  const history = useHistory();
  const { instance, accounts } = useMsal();
  const [data, setData] = React.useState({ agent: null, user: null });
  const [alertInfo, setAlertInfo] = React.useState({ show: false, message: "" });
  const [canShowError, setCanShowError] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [agentList, setAgentList] = React.useState([]);
  const {
    value: { user },
  } = React.useContext(PortalAgentesContext);

  const handleClose = () => {
    cleanForm();
    onClose();
  };

  const url = useMemo(() => {
    if (!info) return "";
    return info.page?.url?.url;
  }, [info]);

  const userList = React.useMemo(() => {
    if (!user?.assignedUsers) return [];
    const { assignedUsers } = user;

    if (assignedUsers.length === 1) {
      setData((prev) => ({ ...prev, user: assignedUsers[0].cdUsuario }));
    }

    return assignedUsers.map(({ cdUsuario, nombreUsuario }) => ({ cdUsuario, nombreUsuario }));
  }, [user, setData, visible]);

  const handleChange = (name, value) => setData({ ...data, [name]: value });

  const generateClassname = (value, customClass) =>
    `ant-row ant-form-item ${value ? "ant-form-item-has-success" : ""} ${customClass ?? ""}`;

  const handleSubmit = (agentId) => {
    const { user, agent } = data;
    if (!user || (!agent && !agentId)) {
      setCanShowError(true);
      return;
    } else {
      history.push(`/datos-generales/${agentId ?? agent}`);
      handleClose();
    }
  };

  const cleanForm = () => {
    setData({ agent: null, user: null });
    setCanShowError(false);
  };
  React.useEffect(() => {
    if (!visible) return;
    const getAgent = async () => {
      if (!data.user) return;
      setIsFetching(true);
      const { user } = data;
      const sendUrl = url.replace("{user}", user);

      const body = {
        method: "get",
        url: sendUrl,
        body: {},
      };
      const res = await Microservice(body, user.idToken, "management", instance, accounts);
      if (res?.status >= 400) {
        return setAlertInfo({
          message: "Se ha presentado un error al consultar los agentes asociados al usuario.",
          show: true,
        });
      }

      const agents = res.filter((r) => r !== 200);
      console.log(agents, "agents");
      if (agents !== null && agents.length === 1) {
        setData((prev) => ({ ...prev, agent: agents[0].key }));
        handleSubmit(agents[0].key);
      }
      if (!agents || !agents?.length) {
        setAlertInfo({
          message: "El usuario seleccionado no cuenta con agentes asociados.",
          show: true,
        });
      }
      setAgentList(agents !== null && agents.length > 0 ? agents : []);
      setIsFetching(false);
    };
    setData((prev) => ({ ...prev, agent: null }));
    setAgentList([]);
    setAlertInfo({ show: false, message: "" });
    getAgent();
  }, [data.user, accounts, instance, visible]); // eslint-disable-line
  return (
    <Modal
      title={
        <h3 className="title__ppal title__ppal--sm title__ppal--c-dr title__ppal--line title__ppal--extra-bold title__ppal--line-c-2 title__ppal--center title__ppal--column">
          <span>Consulta de</span>
          <strong>Agente</strong>
        </h3>
      }
      wrapClassName="modal__default modal__default-flex"
      width="500px"
      footer={null}
      visible={visible}
      onCancel={handleClose}
    >
      <Col xs={24} sm={24} span={24}>
        <Form layout="vertical" className="account-status__container-item-form">
          <Row style={{ width: "100%", margin: "20px 0 16px 0" }}>
            <Col xs={24} sm={12} className={generateClassname(data.user)}>
              <div className="ant-col ant-form-item-label">
                <label id={`slct-user-label`}>Usuario</label>
              </div>
              <Select
                id={`slct-user-label`}
                name="user"
                onChange={(value) => handleChange("user", value)}
                getPopupContainer={(trigger) => trigger.parentNode}
                disabled={userList.length === 1}
                value={data.user}
              >
                {userList.map((user, k) => (
                  <Select.Option value={user.cdUsuario} key={k}>
                    {user.nombreUsuario}
                  </Select.Option>
                ))}
              </Select>
              {data.user === null && canShowError && (
                <div className="alert alert-message">Debes seleccionar una opción.</div>
              )}
            </Col>
            <Col xs={24} sm={12} className={generateClassname(data.agent, "columnLeftSpacing")}>
              <div className="ant-col ant-form-item-label">
                <label id={`slct-agent-label`}>Agente</label>
              </div>
              <Select
                id={`slct-agent`}
                name="agent"
                onChange={(value) => handleChange("agent", value)}
                getPopupContainer={(trigger) => trigger.parentNode}
                disabled={agentList.length === 1 || agentList.length === 0}
                value={data.agent}
                showSearch
                optionFilterProp="children"
              >
                {agentList.map((agent, k) => (
                  <Select.Option value={agent.key} key={k}>
                    {agent.key + (agent.description ? " - " + agent.description : "")}
                  </Select.Option>
                ))}
              </Select>
              {data.agent === null && canShowError && (
                <div className="alert alert-message">Debes seleccionar una opción.</div>
              )}
            </Col>

            <Col xs={24} sm={24}>
              <Alert {...alertInfo} />
            </Col>
            <Col xs={24} sm={24}>
              <Button
                type="primary"
                className="ant-btn solid c-2"
                style={{ margin: "0 auto" }}
                loading={isFetching}
                disabled={isFetching}
                onClick={() => handleSubmit()}
              >
                Aceptar
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Modal>
  );
};
