import { loginRequest, apm } from "authConfig";
import jwt_decode from "jwt-decode";
import moment from "moment";
import "moment/locale/es-mx";
import CryptoJS from "crypto-js";
moment.locale("es-mx");

const Microservice = async (
  bodyRequest,
  idToken,
  path = "services",
  instance,
  accounts,
  isFile
) => {
  idToken = await tokenRenew(idToken || sessionStorage.getItem("idToken"), instance, accounts);

  const accessToken = await tokenApmRenew();

  const secretKey = apm.service.secret;
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(bodyRequest),
    secretKey
  ).toString();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "b2c-token": `${idToken}`,
      "x-api-key": apm.service.xapikey
    },
    body: JSON.stringify({
      infoRequest: encryptedData,
    }),
  };

  try {
    const response = await fetch(`${apm.service.url}/${path}`, requestOptions);
    if (isFile) {
      if (response.status > 299) return await response.json();

      const blob = await response.blob();
      return blob;
    }

    let result = await response.json();
    if (result !== null) {
      result.status = response.status;
    }
    return result;
  } catch (error) {
    console.log(`Error Microservice`, error);
  }
};

export const tokenRenew = async (token, instance, accounts) => {
  var decode = jwt_decode(token);
  const expirationTime = moment.unix(decode.exp);
  const timeNow = moment();
  let newToken = token;

  if (timeNow.isAfter(expirationTime)) {
    const request = { ...loginRequest, account: accounts[0] };
    const response = await instance.acquireTokenSilent(request);
    newToken = response.idToken;
    sessionStorage.setItem("idToken", newToken);
  }
  return newToken;
};

export const tokenApmRenew = async () => {

  let accessToken = sessionStorage.getItem("accessToken");

  // Helper para verificar si el token está a punto de expirar
  const isTokenNearExpiration = (token) => {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
    const expirationTime = decodedToken.exp; // Tiempo de expiración del token en segundos

    // Retorna true si el token expira en los próximos 10 minutos (600 segundos)
    return expirationTime - currentTime < 600;
  };

  // Si hay un token en sessionStorage
  if (accessToken) {
    if (!isTokenNearExpiration(accessToken)) {
      return accessToken;
    }
  }

  try {
    const response = await fetch(apm.token.url, {
      method: "GET",
      headers: {
        "Accept": "application/json",
      }
    });

    const result = await response.json();
    const tokenEncrypted =  result.result;
    const secretToken = apm.token.secret;
    const tokenEncrypted64 = Buffer.from(tokenEncrypted, "base64").toString("utf-8");
    const decryptedBytes = CryptoJS.AES.decrypt(tokenEncrypted64, secretToken).toString(CryptoJS.enc.Utf8);
    const newToken = JSON.parse(decryptedBytes).access_token;

    // Guarda el nuevo token en sessionStorage
    sessionStorage.setItem("accessToken", newToken);

    return newToken;
  } catch (error) {
    console.log(`Error Microservice Token`, error);
  }

}

export { Microservice };
