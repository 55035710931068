// @vendors
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { RichText } from "prismic-reactjs";
import { useHistory } from "react-router-dom";

// @constants
import { HOMEPAGE } from "constants/site";

// @hooks
import usePrismicQuerySingle from "hooks/usePrismicQuerySingle";

// @components
import Layout from "layout";
import DotsCSS from "components/dots-css";
import Loading from "components/loading";

// @styles
import "./styles.scss";
import { Card, Col, Row } from "antd";

export default function Documents() {
  const { info, loading } = usePrismicQuerySingle("documents");
  const history = useHistory();

  const page = useMemo(() => info.page, [info]);

  const folders = useMemo(() => {
    const folders = page?.body?.[0]?.items ?? [];
    if (!folders.length) return [];
    return folders.map((folder) => ({
      title: folder?.nombre_del_fichero?.[0]?.text,
      link: `/documentos/${folder?.enlace_al_fichero?.uid ?? ""}`,
    }));
  }, [page]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{HOMEPAGE.title}</title>
      </Helmet>
      <Layout>
        <section className="sec__default">
          <DotsCSS type="solid solid--r-t" color="c-gr" />
          <DotsCSS type="solid solid--l-b" color="c-gr" />
          <div className="container">
            <h1 className="title__ppal title__ppal--md title__ppal--c-1 title__ppal--extra-bold title__ppal--line title__ppal--line-c-3 title__ppal--center">
              <strong>{RichText.asText(page.section_title)}</strong>
            </h1>
          </div>
        </section>
        <section className="sec__default c-gr">
          <Row className="container folders_documents_container">
            {folders.map((folder, index) => (
              <Col xs={24} sm={12} md={8} lg={5} xl={5} key={index}>
                <Card
                  hoverable
                  className="folder_document_container_root"
                  onClick={() => history.push(folder.link)}
                >
                  <div className="folder_document_title_container">
                    <em className="fas fa-folder-open" />
                    <h4 className="folder_document_title">{folder.title}</h4>
                  </div>

                  <h4 className="folder_document_action">CONOCER MÁS</h4>
                </Card>
              </Col>
            ))}
          </Row>
        </section>
      </Layout>
    </>
  );
}
