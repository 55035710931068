import React from 'react';
import useAuth from 'hooks/useAuth';
import { PortalAgentesProvider } from 'context/context';
import Layout from './Layout';

const Container = () => {
  const { portalAgentesContextValue } = useAuth();

  return (
    <PortalAgentesProvider value={portalAgentesContextValue}>
      <div className="wrapper__container" data-testid="container">
        <Layout />
      </div>	
    </PortalAgentesProvider>
  );

}

export default Container;