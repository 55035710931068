import React from 'react';
import './styles.scss';

const ServicesAssistance = ({ info }) => {
    const renderContent = (items) => {
        return items.map((item, index) => (
            <div key={index} className="item-card">
                {item.title && item.title.length > 0 && (
                    <h3 className="item-title">
                        {item.title.map((t, i) => (
                            <span key={i}>{t.text}</span>
                        ))}
                    </h3>
                )}
                {item.description1 && item.description1.length > 0 && (
                    <p className="list__protects-item--parag item-subtitle description-title">
                        {item.description1.map((d, i) => d.text)}
                    </p>
                )}
                {item.subtitle && item.subtitle.length > 0 && (
                    <h4 className="item-subtitle">
                        {item.subtitle.map((s, i) => (
                            <span key={i} style={{ fontWeight: 'bold' }}>{s.text}</span>
                        ))}
                    </h4>
                )}
                {item.description21 && item.description21.length > 0 && (
                    <p className="list__protects-item--parag item-description">
                        {item.description21.map((d, i) => d.text)}
                    </p>
                )}
            </div>
        ));
    };

    const groupItemsByTitle = (items) => {
        let groupedItems = [];
        let currentGroup = [];
        let currentTitle = '';

        items.forEach((item) => {
            const title = item.title && item.title.length > 0 ? item.title[0].text : '';
            if (title !== '' && title !== currentTitle) {
                if (currentGroup.length > 0) {
                    groupedItems.push({
                        title: currentTitle,
                        items: currentGroup
                    });
                }
                currentTitle = title;
                currentGroup = [item];
            } else {
                currentGroup.push(item);
            }
        });

        if (currentGroup.length > 0) {
            groupedItems.push({
                title: currentTitle,
                items: currentGroup
            });
        }

        return groupedItems;
    };

    const groupedItems = groupItemsByTitle(info.items);

    return (
        <div className="group-life-container">
            <h2 className="title__ppal title__ppal--center title__ppal--sm title__ppal--dr title__ppal--line title__ppal--line-c-3 title__ppal--extra-bold title">
                {info.primary.title.map((t, i) => (
                    <strong key={i}>
                        <span>{t.text}</span>
                    </strong>
                ))}
            </h2>
            <div className="container-group-life">
                {groupedItems.map((group, index) => (
                    <div key={index} className="page-box">
                        <div className="container-backgrounds">
                            {group.items[0].image1 && group.items[0].image1.url && (
                                <img
                                    src={group.items[0].image1.url}
                                    alt={group.items[0].image1.alt || 'Level Image'}
                                    className="background-image"
                                />
                            )}
                        </div>
                        {renderContent(group.items)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServicesAssistance;
