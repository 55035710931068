import React from 'react'
import './styles.scss'

const DotsCSS = ({ type, color, quantity }) => {
    return (
        <ul className={`list__dots ${type} ${color}`}>
            {quantity ? (
                <>
                    <li></li>
                    <li></li>
                    <li></li>
                </>
            ) : (
                <>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </>
            )}
        </ul>
    )
}

export default DotsCSS