import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { PortalAgentesContext, PortalAgentesFormContext } from 'context/context';
import { cotizacion } from 'authConfig';
import './styles.scss';
import { Button } from 'antd';
import DotsCSS from 'components/dots-css';
import Estimate from 'components/estimate';
import ModalMessage from 'components/modal-message';
import ChooseOnlyUser from 'components/choose-only-user';
import { MESSAGES } from 'constants/site';

const Banner = ({ data }) => {

	const { handle: { setSelectedUser }, value: { user, productInfo }  } = useContext(PortalAgentesContext);
	const {
		handle: { setSelectedProduct, setHaveSubProducts, setOptions, setAllowedUsers },
		value: { selectedProduct, haveSubProducts, allowedUsers }
	} = useContext(PortalAgentesFormContext);
	const [openChooseOnlyUser, setOpenChooseOnlyUser] = useState(false);
	const [openEstimate, setOpenEstimate] = useState(false);
	const [formFor, setFormFor] = useState('');
	const [messageModal, setMessageModal] = useState('');
	const [openModal, setOpenModal] = useState(false);

	const closeChooseUser = () => {
		setSelectedUser('');
		setSelectedProduct({});
		setHaveSubProducts(false);
		setOpenChooseOnlyUser(false);
	}

	const openModalChooseUser = () => {
		if (allowedUsers.length > 0 && data.code) {
			setOpenChooseOnlyUser(true);
		} else {
			setMessageModal(MESSAGES.error.cotizacion.codeProduct);
			setOpenModal(true);
		}
	}

	const closeModalMessage = () => {
		setOpenModal(false);
	}

	const setOpenEstimateWith = () => {
		setOpenEstimate(true);
		setFormFor(selectedProduct.selectFlag);
	}

	const selectUser = (cdUsuario) => {
		setSelectedUser(cdUsuario);
		if (haveSubProducts || (selectedProduct && selectedProduct.goCotizador)) {
			setOpenChooseOnlyUser(false);
			setOpenEstimateWith();
		} else {
			const parameters = `?portal=${selectedProduct.report}&username=${cdUsuario}&redirecturi=${selectedProduct.redirectUri}&loginType=B2CAuth&secret=${cotizacion.secret}`;
			window.open(`${cotizacion.url}${parameters}`, '_blank');
			setOpenChooseOnlyUser(false);
		}
	}

	const closeModalEstimate = () => {
		if (!haveSubProducts) {
			setHaveSubProducts(false);
		}
		setOpenEstimate(false);
		setOptions({});
	}

	const validateAllowedUsers = (code) => {
		const allowed = [];
		user.assignedUsers.forEach(u => {
			u.soluciones.forEach(app => {
				if (app.clave.toString() === code) {
					allowed.push(u);
				}
			})
		});
		setAllowedUsers(allowed);
	}

	useEffect(() => {
		if (allowedUsers.length === 0 && data.code) {
			validateAllowedUsers(data.code);
		}
	}, [allowedUsers]);

	useEffect(() => {
		if (productInfo && !selectedProduct.goCotizador) {
			productInfo.forEach((x) => {
				const compareText = x.select_flag[0]?.text === 'responsabilidad-civil' ? 'rc' : x.select_flag[0]?.text
				if (window.location.pathname.includes(compareText)) {
					const producto = {
						solucion: x.solucion[0]?.text ? x.solucion[0].text : "",
						selectFlag: x.select_flag[0]?.text,
						claveProducto: x.clave_producto[0]?.text ? x.clave_producto[0].text : "",
						goCotizador: x.go_cotizador,
						urlCotizador: x.url_cotizador[0]?.text ? x.url_cotizador[0].text : "",
						product: x.product
					}
					setSelectedProduct(producto);
				}
			})
		}
	}, [productInfo])

	return (
		<>
			<div className="solution__banner">
				<div className="solution__banner-description">
					<Link to="/soluciones" className="solution__banner-description--previous">
						<em className="fal fa-long-arrow-left" /> Volver
					</Link>
					<h1 className="solution__banner-description--name" id='seguro-detalle-title'>
						{data.icon_image.url ? (
							<img src={data.icon_image.url} alt={data.icon_image.alt} />
						) : (
							<em className={`fal fa-${RichText.asText(data.icon)}`} />
						)}
						{data.name}
					</h1>
					{data.description && (
						<div className="solution__banner-description--parag" id='seguro-detalle-parag'>
							<RichText render={data.description} />
						</div>
					)}
					<div className="solution__banner-description--actions">
						<Button
							id='seguro-detalle-btn-cotizar'
							className="solid c-3"
							onClick={openModalChooseUser}
						>
							Cotizar
						</Button>
					</div>
				</div>
				<div className="solution__banner-image">
					<DotsCSS type="outline" color="c-wh" />
					{data.image.url && (
						<img src={data.image.url} alt={data.image.alt} id='seguro-detalle-image' />
					)}
				</div>
			</div>
			<ChooseOnlyUser
				users={allowedUsers}
				redirectTo={"urlService"}
				visible={openChooseOnlyUser}
				onClose={() => closeChooseUser()}
				selectUser={(cdUsuario) => selectUser(cdUsuario)}
			/>
			<Estimate open={openEstimate} closeModal={() => closeModalEstimate()} name={data.uid} />
			<ModalMessage open={openModal} closeModal={closeModalMessage} message={messageModal} />

		</>
	)
}

export default Banner;