import React, { useContext, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { Col, Form, Input } from 'antd'
import { PortalAgentesFormContext } from 'context/context';

// @utils
import { replaceAccents } from 'utils/replace-accents';
import { handlePreventKeys } from 'utils/utils';

const FieldText = ({ data }) => {
    const [hide, setHide] = useState(false);

    const {
        handle: { handleInputChange },
        value: { dataFields }
    } = useContext(PortalAgentesFormContext);

    useEffect(() => {
        if (dataFields && data && data.relationship) {
            setHide(true);
            if (dataFields['userId']) {
                const relationship = JSON.parse(data.relationship);
                let result = false;
                Object.keys(relationship).forEach(rpKey => {
                    result = !relationship[rpKey].includes(dataFields[rpKey]);
                });
                setHide(result)
            }
        }
    }, [dataFields])

    return (
        <Col xs={24} sm={12} md={12} lg={8}>
            <Form.Item hidden={hide}>
                <Input
                    type="text"
                    id={`txt-${replaceAccents(RichText.asText(data.name))}`}
                    placeholder=" "
                    name={data.key}
                    maxLength={data.limit}
                    autoComplete="off"
                    data-testid="inputText"
                    value={dataFields[data.key]}
                    onChange={(e) => handleInputChange(e)}
                    onKeyPress={(e) => data.type === 'Campo numérico' ?
                        handlePreventKeys(e, 'number') : handlePreventKeys(e, 'text')
                    }
                />
                <label
                    className="ant-label"
                    id={`txt-${replaceAccents(RichText.asText(data.name))}-label`}
                    style={{fontSize: '14px'}}
                >
                    {RichText.asText(data.name)}
                </label>
            </Form.Item>
        </Col>
    )
}

export default FieldText;