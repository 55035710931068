import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'
import Post from './post'
import './styles.scss'

const List = ({ items }) => {
	const [categories, setCategories] = useState([]);
	const [filtered, setFiltered] = useState([]);

	useEffect(() => {
		if (items.length) {
			const categoriesIndexes = [];
			items.forEach(category => {
				if (!categoriesIndexes.includes(category.data.category)) {
					categoriesIndexes.push(category.data.category);
				}
			})
			setCategories(categoriesIndexes);
			setFiltered(items);
		}
	}, [items]);

	function changeCategory(value) {
		let newsPost = [];
		if (value === 'Todos') {
			newsPost = items;
		} else {
			newsPost = items.filter(({ data: { category } }) => category === value);
		}
		setFiltered(newsPost);
	}

	return (
		<>
			<Form layout="vertical" className="ant-form--filtered">
				<Form.Item label="Categorías" className="ant-form-item-label-top">
					<Select 
						defaultValue='Todos' 
						onChange={(value) => changeCategory(value)} 
						className="ant-select--filtered" 
						data-testid="select-change"
						getPopupContainer={trigger => trigger.parentNode}
					>
						<Select.Option value="Todos" key="Todos" data-testid="Todos">
							Todos
						</Select.Option>
						{categories.map((category, k) => {
							return (
								<Select.Option value={category} key={category} data-testid={`option${k}`}>{category}</Select.Option>
							)
						})}
					</Select>
				</Form.Item>
			</Form>
			<div className="news__list">
				{filtered.map((item, index) => {
					return (
						<Post key={item.id} index={index} info={item} />
					)
				})}
			</div>
		</>
	)
};

export default List;