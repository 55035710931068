// @vendors
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { Col, Row } from "antd";

// @constants
import { HOMEPAGE } from "constants/site";

// @hooks
import usePrismicQuerySingle from "hooks/usePrismicQuerySingle";

// @components
import Layout from "layout";
import DotsCSS from "components/dots-css";
import Loading from "components/loading";
import DownloadDocumentCard from "components/download-document-card";

// @styles
import "./styles.scss";

export default function ImportantDocuments() {
  const { info, loading } = usePrismicQuerySingle("pagina_de_documentos_de_interes");

  const page = useMemo(() => info.page, [info]);
  const title = useMemo(() => page?.titulo?.[0]?.text, [page]);
  const documents = useMemo(() => {
    const docs = page?.body?.[0]?.items ?? [];

    if (!docs.length) return [];

    return docs.map((doc) => ({
      image: doc?.imagen_del_documento?.url,
      text: doc?.descripcion_del_documento?.[0]?.text,
      downloadLink: doc?.documento?.url,
      link: doc?.redireccion?.url,
    }));
  }, [page]);

  if (loading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>{HOMEPAGE.title}</title>
      </Helmet>
      <Layout>
        <section className="sec__default">
          <DotsCSS type="solid solid--r-t" color="c-gr" />
          <DotsCSS type="solid solid--l-b" color="c-gr" />
          <div className="container">
            <h1 className="title__ppal title__ppal--md title__ppal--c-1 title__ppal--extra-bold title__ppal--line title__ppal--line-c-3 title__ppal--center">
              <strong>{title}</strong>
            </h1>
          </div>
        </section>

        <Row className=" important_document_container">
          {documents.map((doc, index) => (
            <Col xs={24} sm={12} md={8} lg={5} xl={4} key={index}>
              <DownloadDocumentCard
                image={doc.image}
                text={doc.text}
                downloadLink={doc.downloadLink}
                link={doc.link}
              />
            </Col>
          ))}
        </Row>
      </Layout>
    </>
  );
}
