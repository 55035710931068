// @vendors
import React, { useMemo } from 'react';
import { RichText } from 'prismic-reactjs';
import { Helmet } from 'react-helmet';


// @constants
import { HOMEPAGE } from 'constants/site';

// @hooks
import usePrismicQuerySingle from 'hooks/usePrismicQuerySingle';

// @components
import Layout from 'layout';
import DotsCSS from 'components/dots-css';
import Insurances from 'components/insurances';
import Loading from 'components/loading';


const Solutions = () => {
    const { info, loading } = usePrismicQuerySingle('solutions');
    const page = useMemo(() => info.page, [info]);


    if (!loading) {
        const solutions = [
            {
                title: page.people_name,
                icon: page.people_icon,
                image: page.people_image,
                items: [
                    ...page.body1
                ]
            },
            {
                title: page.business_name,
                icon: page.business_icon,
                image: page.business_image,
                items: [
                    ...page.body2
                ]
            }
        ]

        return (
            <>
                <Helmet>
                    <title>{RichText.asText(page.section_title)} - {HOMEPAGE.title}</title>
                </Helmet>
                <Layout>
                    <section className="sec__default">
                        <DotsCSS type="solid solid--r-t" color="c-gr" />
                        <DotsCSS type="solid solid--l-b" color="c-gr" />
                        <div className="container">
                            <h1 className="title__ppal title__ppal--md title__ppal--c-1 title__ppal--extra-bold title__ppal--line title__ppal--line-c-3 title__ppal--center">
                                <strong>{RichText.asText(page.section_title)}</strong>
                            </h1>
                        </div>
                    </section>
                    <Insurances items={solutions} />
                </Layout>
            </>
        )
    } else {
        return <Loading />
    }
}

export default Solutions