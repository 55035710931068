// @vendors
import React, { useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Col, Row } from "antd";

// @constants
import { HOMEPAGE } from "constants/site";

// @hooks
import usePrismicQueryByTags from "hooks/usePrismicQueryByTags";

// @components
import Layout from "layout";
import DownloadDocumentCard from "components/download-document-card";
import DotsCSS from "components/dots-css";
import Loading from "components/loading";

// @styles
import "./styles.scss";

export default function DocumentsSections() {
  const { uid } = useParams();
  const history = useHistory();

  const { info: data, loading } = usePrismicQueryByTags("documentos_varios", [uid]);

  const page = useMemo(
    () => ({
      titulo: data[0]?.data?.encabezado,
      body: data[0]?.data?.body,
    }),
    [data]
  );
  const title = useMemo(() => page?.titulo?.[0]?.text, [page]);
  const documents = useMemo(() => {
    const docs = page?.body?.[0]?.items ?? [];

    if (!docs.length) return [];
    const documents = docs.map((doc) => ({
      image: doc?.imagen_del_documento?.url,
      text: doc?.descripcion_del_documento?.[0]?.text,
      downloadLink: doc?.documento?.url,
      link: doc?.redireccion?.url,
    }));
    return documents.filter((doc) => doc.image && doc.text);
  }, [page]);

  if (loading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>{HOMEPAGE.title}</title>
      </Helmet>
      <Layout>
        <section className="sec__default decument_section_title_container">
          <DotsCSS type="solid solid--r-t" color="c-gr" />
          <DotsCSS type="solid solid--l-b" color="c-gr" />

          <div className="decument_section_button_container ">
            <Button
              className="decument_section_back_button"
              onClick={() => history.push("/documentos")}
            >
              <em className="fal fa-arrow-left" />
              Volver
            </Button>
          </div>
          <div className="container ">
            <h1 className="title__ppal title__ppal--md title__ppal--c-1 title__ppal--extra-bold title__ppal--line title__ppal--line-c-3 title__ppal--center">
              <strong>Documentos {title}</strong>
            </h1>
          </div>
        </section>

        <Row className=" document_section_container">
          {documents.map((doc, index) => (
            <Col xs={24} sm={12} md={8} lg={5} xl={4} key={index}>
              <DownloadDocumentCard
                image={doc.image}
                text={doc.text}
                downloadLink={doc.downloadLink}
                link={doc.link}
              />
            </Col>
          ))}
        </Row>
      </Layout>
    </>
  );
}
