// @vendors
import React from 'react'

// @styles
import './styles.scss'

// @images
import SuraImage from 'assets/images/loading.png'

export default function Loading() {
    return (
        <div className='loading'>
            <img src={SuraImage} alt='Cargando' />
            <span>Esto puede tardar unos segundos...</span>
        </div>
    )
}