// @vendors
import React, { useContext, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";

// @constants
import { HOMEPAGE } from "constants/site";

// @hooks
import usePrismicQuerySingle from "hooks/usePrismicQuerySingle";

// @components
import ModalNotification from "components/modal-notification";
import HomeBanner from "components/homepage/banner";
import Layout from "layout";
import Slice from "./slice";
import Loading from "components/loading";

// @styles
import "./styles.scss";
import { PortalAgentesFormContext } from "context/context";
import { Questions } from "components/homepage/questions";

const Homepage = () => {
  const { info, loading } = usePrismicQuerySingle("homepage");
  const { info: docs, loading: loadingDocs } = usePrismicQuerySingle("pagina_de_documentos_de_interes");
  const [showModal, setShowModal] = React.useState(false);
  const [notification, setNotification] = React.useState({});
  const home = useMemo(() => info.page, [info]);
  const {
    handle: { setSelectedProduct },
  } = useContext(PortalAgentesFormContext);

  const questions = useMemo(
    () => home?.body?.find(({ slice_type }) => slice_type === "preguntas_frecuentes"),
    [home?.body]// eslint-disable-line
  );
  const manageNotification = React.useCallback(() => {
    const isNotificationDisplayed = localStorage.getItem("isNotificationDisplayed");
    const notification = home?.body.find((item) => item.slice_type === "notificaciones");
    if (!isNotificationDisplayed && Object.keys(notification ?? {}).length) {
      const {
        descripcion,
        titulo,
        enlace_de_redireccion: { url },
        mostrar_notificacion,
      } = notification.primary;
      if (mostrar_notificacion) {
        setNotification({ descripcion, titulo, url });
        setShowModal(true);
        localStorage.setItem("isNotificationDisplayed", true);
      }
    }
  }, [home?.body]);// eslint-disable-line

  useEffect(() => {
    manageNotification();
    setSelectedProduct({});
  }, [manageNotification]);// eslint-disable-line

  if (!loading) {
    return (
      <>
        <Helmet>
          <title>{HOMEPAGE.title}</title>
        </Helmet>
        <Layout>
          <HomeBanner data={home} />
          <section className="sec__default">
            <div className="container cards__container">
              <Slice data={home.body} docs={docs} loadingDocs={loadingDocs} />
            </div>
          </section>

          <Questions info={questions} />
        </Layout>

        <ModalNotification
          open={showModal}
          closeModal={() => setShowModal(false)}
          message={notification.descripcion}
          title={notification.titulo}
          link={notification.url}
        />
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Homepage;
