import { useEffect, useState } from 'react'
import { useMsal } from "@azure/msal-react";
import { loginRequest, perfilamiento, apiGraph } from "../authConfig";
import { Microservice } from "services/microservice";
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import jwt_decode from "jwt-decode";

const useAuth = () => {
  const { instance, accounts } = useMsal();
  const [userProfile, setUserProfile] = useState({});
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [name, setName] = useState('');
  const [idToken, setIdToken] = useState('');
  const [email, setEmail] = useState('');
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [selectedUser, setSelectedUser] = useState('');
  const [lastSing, setLastSing] = useState('');
  const [subId, setSubId] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [showCallCenter, setShowCallCenter] = useState(false);
  const [showEstadoCuenta, setShowEstadoCuenta] = useState(false);
  const [listUserEstadoCuenta, setListUserEstadoCuenta] = useState([]);
  const [productInfo, setProductInfo] = useState([]);

  const requestAccessToken = async () => { 
    const request = { ...loginRequest, account: accounts[0] };
    try {
      const response = await instance.acquireTokenSilent(request)
      sessionStorage.setItem('idToken', response.idToken);
      setIdToken(response.idToken);
    } catch (error) {
      const response = await instance.acquireTokenRedirect(request)
      sessionStorage.setItem('idToken', response.idToken);
      setIdToken(response.idToken);
    }
  }
  
  const getUserProfile = async (usermail) => {
    const body = {
    	method: "get",
    	url: `${perfilamiento.url}?username=${usermail}`,
    	body: {}
    }
    const res = await Microservice(body, idToken, 'management', instance, accounts);
    setEmail(usermail);
    setUserProfile(res);
    if (res.success) {
      const usuarios = res.usuarios;
      if (usuarios.length) {
        setAssignedUsers(usuarios);
      }
    }
    setLoadingProfile(false);
  } 

  const getLastConnection = async () => {
    var decode = jwt_decode(idToken);
    const body = {
      method: "get",
      url: `${apiGraph.url}/${decode.oid}?$select=${apiGraph.attributes},${getExtensionAttributes()}`,
      body: {}
    }
    const res = await Microservice(body, idToken, 'graph', instance, accounts);
    const datetime = format(new Date(res.signInActivity.lastSignInDateTime), "dd 'de' MMMM 'de' yyyy 'a las' hh:mmaaaa", { locale: es })
    setLastSing(datetime);
    setUserInfo(res);
  }

  const getExtensionAttributes = () => {
    let params = '';
    const split = apiGraph.extendedAttributes.split(',');
    split.forEach(attr => params = `extension_${apiGraph.extendedAttributesClientId}_${attr},${params}`);
    console.log(params);
    return params.replace(/.$/, '');
  }

  useEffect(() => {
    if (accounts.length > 0) {
      if(!idToken) {
        requestAccessToken();
      } else {
        getLastConnection();
        setName(accounts[accounts.length - 1].name);
        setSubId(accounts[accounts.length - 1].idTokenClaims.sub)
        getUserProfile(accounts[accounts.length - 1].username);
      }
    }
  }, [accounts, idToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const portalAgentesContextValue = {
    handle: {
      setSelectedUser,
      setShowCallCenter,
      setShowEstadoCuenta,
      setListUserEstadoCuenta,
      setProductInfo
    },
    value: {
      user: {
        userProfile,
        assignedUsers,
        selectedUser,
        name,
        email,
        idToken,
        subId,
        lastSing,
        userInfo
      },
      loadingProfile,
      showCallCenter,
      showEstadoCuenta,
      listUserEstadoCuenta,
      productInfo
    }
  }

  return { portalAgentesContextValue };

}

export default useAuth;

