import React from "react";

export const Tableu = ({ src, token }) => {
  return (
    <div style={{ maxWidth: "100%", margin: "0 auto" }}>
      <tableau-viz
        id="tableau-viz"
        src={src}
        width="100%"
        height="840"
        hide-tabs
        toolbar="bottom"
        token={token}
      />
    </div>
  );
};
