import React, { Suspense } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import esEs from "antd/lib/calendar/locale/es_ES";
import "antd/dist/antd.css";
import "App.scss";
import TagManager from "react-gtm-module";
import Homepage from "pages/homepage";
import Solutions from "pages/solutions";
import Services from "pages/services";
import Historical from "pages/historical";
import HistoricalPage from "pages/historicalpage";
import News from "pages/news";
import Documents from "pages/documents";
import Post from "pages/post";
import SolutionDetail from "pages/solution-detail";
import ErrorPage from "pages/errors";
import CallCenter from "pages/call-center";
import Container from "layout/Container";
import { PortalAgentesFormProvider } from "context/context";
import { useForm } from "hooks/useForm";

// Msal imports
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import ImportantDocuments from "pages/important-documents";
import DocumentsSections from "pages/documents-sections";
import GeneralDetails from "pages/general-details";
import MyReports from "pages/my-reports";
import { CardManagement } from "pages/card-management";
import MyPolices from "pages/my-polices";
import { TypeForm } from "components/type-form";
import ServicesAssistance from "pages/group-life";

const App = ({ pca }) => {
  const authRequest = { ...loginRequest };
  const tagManagerArgs = { gtmId: process.env.REACT_APP_GTM };
  TagManager.initialize(tagManagerArgs);

  return (
    <Suspense fallback={<div>Cargando</div>}>
      <ConfigProvider locale={esEs}>
        <BrowserRouter>
          <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={authRequest}
            >
              <Container />
            </MsalAuthenticationTemplate>
          </MsalProvider>
        </BrowserRouter>
      </ConfigProvider>
    </Suspense>
  );
};

export const Routes = ({userProfile}) => {
  const { formContextValue } = useForm();

  return (
    <PortalAgentesFormProvider value={formContextValue}>
      <TypeForm userProfile={userProfile}/>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/soluciones" component={Solutions} />
        <Route exact path="/soluciones/:uid" component={SolutionDetail} />
        <Route exact path="/servicios" component={Services} />
        <Route exact path="/historiales" component={HistoricalPage} />
        <Route exact path="/historiales/:uid" component={Historical} />
        <Route exact path="/documentos" component={Documents} />
        <Route exact path="/documentos/:uid" component={DocumentsSections} />
        <Route exact path="/novedades" component={News} />
        <Route exact path="/novedades/:uid" component={Post} />
        {/* <Route exact path="/estados-cuenta" component={AccountStatus} /> */}
        <Route exact path="/documentos-importantes" render={() => <ImportantDocuments />} />
        <Route exact path="/call-center/" render={() => <CallCenter />} />
        <Route exact path="/undefined" render={() => <ServicesAssistance />} />
        <Route exact path="/datos-generales/:uid" render={() => <GeneralDetails />} />
        <Route exact path="/mis-reportes/:uid" render={() => <MyReports />} />
        <Route exact path="/gestion-cartera/:uid" render={() => <CardManagement />} />
        <Route exact path="/polizas/:uid" render={() => <MyPolices />} />
        <Route>
          <ErrorPage type="error" />
        </Route>
      </Switch>
    </PortalAgentesFormProvider>
  );
};

export default App;
