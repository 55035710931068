import React from 'react'
import ImageGallery from 'react-image-gallery'
import { RichText } from 'prismic-reactjs'
import { Link } from 'react-router-dom';
import { linkResolver } from 'prismic-config';
import './styles.scss'
import DotsCSS from 'components/dots-css'

const HomeBanner = ({ data }) => {

    const items = data.banner;

    function _renderCustom(dataDescription) {
        const item = JSON.parse(dataDescription.description)
        const pos = JSON.parse(dataDescription.k)
        return (
            <div>
                <img src={item.image.url} alt={item.image.alt} className={`image-gallery-image ${item.full_image ? 'image-full' : ''}`} />
                <div className="image-gallery-description">
                    <h3
                        className="title__ppal title__ppal--c-wh title__ppal--sm"
                        id={`banner-home-${pos}-title`}
                    >
                        {RichText.asText(item.title)}
                    </h3>
                    {
                        item.button_link?.link_type !== 'Document'?(
                            <a
                            href={item.button_link.url}
                            className="ant-btn solid c-3"
                            id={`banner-home-${pos}-btn`}
                            target='_blank'
                            rel="noopener noreferrer"
                        >
                            {RichText.asText(item.button_label)}
                        </a>

                        ):(<Link
                        to={linkResolver(item.button_link)}
                        className="ant-btn solid c-3"
                        id={`banner-home-${pos}-btn`}
                    >
                        {RichText.asText(item.button_label)}
                    </Link>)
                    }
                    
                </div>
            </div>
        )
    }

    return (
        <section className="home__banner">
            <DotsCSS type="outline" color="c-wh" />
            <DotsCSS type="solid" color="c-wh" />
            <ImageGallery
                items={items.map((item, index) => ({
                    description: JSON.stringify(item),
                    k: index,
                    original: item.image.url ? item.image.url : ''
                }))}
                renderItem={_renderCustom}
                showPlayButton={false}
                showFullscreenButton={false}
                showThumbnails={false}
                showBullets={true}
            />
        </section>
    )
}

export default HomeBanner;