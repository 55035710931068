import { useState, useContext } from 'react';
import { PortalAgentesContext } from 'context/context';
import { Microservice } from "services/microservice";
import { requestReport } from 'constants/site';
import { differenceInDays } from 'date-fns';
import moment from 'moment';
import { useMsal } from '@azure/msal-react';

export const useForm = () => {
  const { value: { user } } = useContext(PortalAgentesContext);
  //Historiales
  const [dataSource, setDataSource] = useState([]);
  const [dataFields, setDataFields] = useState({});
  const [columnsInfo, setColumnsInfo] = useState({});
  const [fechas, setFechas] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [redirectUriCotizador, setRedirectUriCotizador] = useState('');
  const [redirectValue, setRedirectValue] = useState({});
  const [reportServiceObj, setReportServiceObj] = useState({});
  //Comisiones
  const [consultStats, setConsultStats] = useState(false);
  const [userForSearching, setUserForSearching] = useState('');
  //Soluciones
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [haveSubProducts, setHaveSubProducts] = useState(false);
  const [disabledFieldsEstimateForm, setDisabledFieldsEstimateForm] = useState({});
  const [disabledQuotationButton, setDisabledQuotationButton] = useState(false);
  const [options, setOptions] = useState({});
  const { instance, accounts } = useMsal();
  //Call Center
  const [callCenterConsult, setCallCenterConsult] = useState(false);

  const reset = () => {
    setDisabledButtons(true);
    setDataSource([]);
    setDataFields([]);
    setFechas({});
    setUserForSearching('');
    setLoadingData(false);
  };

  const handleInputChange = (event) => {
    let value = event.target.value;
    if (!event.target.value == null) {
      value = value.toUpperCase();
    }
    setDataFields({ ...dataFields, [event.target.name]: value });
    if (userForSearching.length) {
      if (disabledButtons) {
        setDisabledButtons(false);
      }
    }
  };

  const handleSelectChange = (name, value) => {
    setDataFields(() => ({ ...dataFields, [name]: value }));
    if (name === 'userId') {
      setUserForSearching(value);
      if (disabledButtons) {
        setDisabledButtons(false);
      }
    }
    if (name === 'solucion' && value !== null) {
      setRedirectUriCotizador(value);
      setShowAlert(false);
    }
  };

  const handleInputDate = (date) => {
    setFechas(date);
  }

  const handleRadioChange = (name, value) => {
    setDataFields(() => ({ ...dataFields, [name]: value}))
  }

  const consultarPoliza = async (urlService) => {

    const body = {
      method: 'get',
      url: urlService,
      body: {}
    }
    console.log({"user.idToken": user.idToken})
    try {
      const response = await Microservice(body, user.idToken, "management", instance, accounts);
      return response?.data;
      // return response;
    } catch (error) {
      console.log(`Error consulta histórico`, error);
      setLoadingData(false);
    }
  }

  const consultHistorical = async (urlService) => {
    setDataSource([]);
    const bodyRequest = { ...dataFields };
    const body = {
      method: 'POST',
      url: `${urlService}`,
      body: bodyRequest
    }
    try {
      const response = await Microservice(body, user.idToken, instance, accounts);
      response.forEach((resRow) => {
        let tipoValor = '';
        switch (resRow.solucion) {
          case 'PES':
            tipoValor = '(Anual)';
            break;
          case 'RC':
            tipoValor = '(Anual)';
            break;
          case 'ALA AZUL PLUS':
            tipoValor = '(Mensual)';
            break;
          case 'AUTOS':
            tipoValor = '(Anual)';
            break;
          default:
            break;
        }
        resRow.valor = `${resRow.valor} ${tipoValor}`
      });
      return response;
    } catch (error) {
      console.log(`Error consulta histórico`, error);
      setLoadingData(false);
    }
  }

  const consultReportUAT = async (urlService) => {
    const bodyRequest = { ...requestReport };
    bodyRequest.customFilters[0].data['value'] = dataFields.userId;
    const body = {
      method: 'POST',
      url: `${urlService}`,
      body: bodyRequest
    }
    try {
      const response = await Microservice(body, user.idToken, 'reports', instance, accounts);
      if (response !== null) {
        return response;
      } else {
        return []
      }
    } catch (error) {
      console.log(`Error consulta reporte`, error);
      throw new Error("Error consulta reporte");
    }
  }

  const customRequest = async (config) => {
    let url = config && config.method === 'GET' ? getUrl(config.url, dataFields) : config.url;
    const body = { method: config.method, url: url, body: config.body }
    try {
      const response = await Microservice(body, user.idToken, 'management');
      if (response !== null) {
        return response;
      } else {
        return []
      }
    } catch (error) {
      console.log(`Error consulta reporte`, error);
      throw new Error("Error consulta reporte");
    }
  }

  const getUrl = (url, dataFields) => {
    let urlBase = "";
    if (dataFields) {
      const objUrl = new URL(url);
      const params = new URLSearchParams(objUrl.search.slice(1));
      urlBase = new URL(objUrl.origin + objUrl.pathname);
      let value = "";
      for (let p of params) {
        value = p[0] === 'customParams' ? getCustomParams(dataFields) :
          p[1] && p[1] !== 'value' ? p[1] : null;
        value && urlBase.searchParams.set(p[0], value);
      }
    }

    return urlBase ? urlBase.href : url;
  }

  const stateValue = {
    ED001: 15, ED002: 1000, ED003: 5,
    ED004: 4, ED005: 3, ED006: 2, ED007: 1
  }

  const conditionalFilters = (filter, days, end_date) => {
    const divide = { $divide: [{ $subtract: [{ $toDate: end_date }, filter] }, 3600000] };
    return { $expr: { $and: [{ $gte: [divide, days * 24] }, { $lt: [divide, (days + 1) * 24] }] } };
  }

  const getCustomParams = (dataFields) => {
    let filtersArray = [];
    const newDate = new Date();
    const format = "YYYY-MM-DD HH:mm:ss";

    if (callCenterConsult) {
      dataFields.estado = 'ED001';
    }

    dataFields && Object.keys(dataFields).forEach(key => {
      if (dataFields[key]) {
        switch (key) {
          case 'cotizacion':
            filtersArray.push({
              $or: [{ cotizaciones: { $elemMatch: { "policy.policyNumber": Number(dataFields[key]) } } },
              { cotizaciones: { $elemMatch: { "poliza.numeroPoliza": String(dataFields[key]) } } }]
            });
            break;
          case 'folio':
            filtersArray.push({ 'configuracion.folio': Number(dataFields[key]) });
            break;
          case 'nombre':
            filtersArray.push({ componentes: { $elemMatch: { value: String(dataFields[key]) } } });
            break;
          case 'estado':
            const dataFieldValue = dataFields[key];
            const days = reportServiceObj && reportServiceObj[dataFields.solucion].daysToRecover ? reportServiceObj[dataFields.solucion].daysToRecover : stateValue[dataFieldValue];
            const start_date = moment(newDate).subtract(days, 'days');
            const end_date = (dataFieldValue === 'ED002') ? moment(newDate).subtract(stateValue['ED001'], 'days') : moment(newDate);
            const filter = { $gte: start_date.format(format), $lt: end_date.format(format) };

            const validation = dataFieldValue === 'ED002' || dataFieldValue === 'ED001' ?
              [{ cotizaciones: { $elemMatch: { fechaCreacion: filter } } }, { "configuracion.fechaCreacion": filter }] :
              [conditionalFilters({ $toDate: { $arrayElemAt: ["$cotizaciones.fechaCreacion", -1] } }, days, end_date.format(format)),
              conditionalFilters({ $toDate: "$configuracion.fechaCreacion" }, days, end_date.format(format))]

            filtersArray.push({ $or: validation });
            break;
          case 'fechaDesde':
          case 'fechaHasta':
            const start_date_value = dataFields['fechaDesde'] ? moment(new Date(dataFields['fechaDesde'])) : moment(newDate).subtract(10000, 'days');
            const end_date_value = dataFields['fechaHasta'] ? moment(new Date(dataFields['fechaHasta'])).add(86399, 'seconds') : moment(newDate);
            const filterVal = { $gte: start_date_value.format(format), $lt: end_date_value.format(format) };
            filtersArray.push({
              $or: [{ cotizaciones: { $elemMatch: { fechaCreacion: filterVal } } },
              { "configuracion.fechaCreacion": filterVal }]
            });
            break;
          case 'userId':
            filtersArray.push({
              $and: [{ "datosAgente.usuarioId": dataFields[key] },
              { "datosAgente.email": user.email }]
            });
            break;
          case 'rfc':
            filtersArray.push({ componentes: { $elemMatch: { value: String(dataFields[key]) } } });
          default:
            break;
        }
      }
    });
    if(!dataFields.estado && !dataFields.fechaDesde && !dataFields.fechaHasta) {
      const days = reportServiceObj && reportServiceObj[dataFields.solucion].daysToRecover && reportServiceObj[dataFields.solucion].daysToRecover 
      const start_date = moment(newDate).subtract(days, 'days');
      const end_date =  moment(newDate);
      const filter = { $gte: start_date.format(format), $lt: end_date.format(format) };

      const validation = [{ cotizaciones: { $elemMatch: { fechaCreacion: filter } } }, { "configuracion.fechaCreacion": filter }] 

      filtersArray.push({ $or: validation });
    }

    filtersArray.push({ "configuracion.estadoFlujo": "Activo" });
    let customParams = {
      filter: { $and: filtersArray }, projection: {
        configuracion: { folio: 1, fechaCreacion: 1 },
        cotizaciones: {
          poliza: { numeroPoliza: 1 }, planes: { coberturas: 1 }, fechaCreacion: 1,
          policy: { policyNumber: 1 }, costs: { amount: 1 }
        },
        datosAgente: { usuarioId: 1, ramoId: 1, oficinaId: 1, name: 1, email: 1 },
        componentes: { key: 1, value: 1}
      }
    };
    return JSON.stringify(customParams);
  }

  const mergeResults = (historical, report) => {
    if (!Array.isArray(report)) {
      const results = [];
      const rows = report && report.data && report.data.rows;
      let newResReport = false;
      if (rows && rows.length) {
        const entries = Object.entries(columnsInfo);
        rows.forEach((row) => {
          newResReport = row && row.newResReport;
          const obj = { ...columnsInfo };
          entries.forEach((keyValue) => {
            if (keyValue[0] !== 'solucion') {
              const split = keyValue[1].split('+') ? keyValue[1].split('+') : keyValue[1];
              if (split.length > 1) {
                let value = '';
                split.forEach(prop => {
                  if (row[prop] !== null) {
                    value = value + row[prop] + ' ';
                  } else {
                    value = value + '';
                  }
                });
                obj[keyValue[0]] = value;
              } else {
                if (keyValue[0] === 'estado') {
                  const days = calculateDifferenceInDays(row['creation_date']);
                  obj[keyValue[0]] = calculateStateByDate(days, row['creation_date']);
                } else {
                  obj[keyValue[0]] = row[keyValue[1]];
                }
              }
            } else {
              obj[keyValue[0]] = getSolutionName();
            }
          });
          if (obj.estado !== "VENCIDA") {
            results.push(obj);
          }
        });
      }
      const filteredResults = newResReport ? results : getValuesFromFilterObject(results);
      setDataSource(filteredResults);
    } else {
      setDataSource(historical);
    }
  }

  const calculateDifferenceInDays = (date) => {
    if (date !== null) {
      const today = new Date();
      const days = differenceInDays(today, new Date(Date.parse(date)));
      return days;
    } else {
      return null
    }
  }

  const calculateStateByDate = (days, date) => {
    if (days !== null) {
      if (days > 5) {
        return days >= reportServiceObj[dataFields.solucion].daysToRecover ? 'VENCIDA' : 'VIGENTE';
      } else {
        const hours = days === 0 && moment().diff(moment(date), 'hours');
        return days > 1 ? `${days} DIAS` : days === 0 ? `${hours} HORAS` : `${days} DIA`;
      }
    } else {
      return null;
    }
  }

  const getValuesFromFilterObject = (results) => {
    const filterObject = { ...dataFields };
    const keys = Object.keys(filterObject).filter(x => x !== 'solucion' && x !== 'userId');
    const between = filterObject['fechaDesde'] && filterObject['fechaHasta'] ? true : false;
    const filters = {};
    keys.forEach((key) => {
      if (filterObject[key] !== null) {
        filters[key] = filterObject[key];
      }
    });
    return findByTemplate(results, filters, between);
  }

  const findByTemplate = (array, filter, betweenDates) => {
    return array.filter(item => {
      return Object.keys(filter).every(prop => {
        if (betweenDates) {
          return moment(item['fechaCotizacion']).isBetween(filter['fechaDesde'], filter['fechaHasta'], undefined, '[]');
        } else {
          if (prop === 'fechaDesde') {
            return moment(item['fechaCotizacion']).isSameOrAfter(filter['fechaDesde']);
          } else if (prop === 'fechaHasta') {
            return moment(item['fechaCotizacion']).isSameOrAfter(filter['fechaHasta']);
          }
        }
        if (prop === 'estado') {
          return item[prop] === getStateDescription();
        } else {
          return item[prop] === filter[prop] || item[prop].includes(filter[prop])
        }
      });
    });
  }

  const getStateDescription = () => {
    switch (dataFields.estado) {
      case 'ED001':
        return 'VIGENTE';
      case 'ED002':
        return 'VENCIDA';
      case 'ED003':
        return '5 DIAS';
      case 'ED004':
        return '4 DIAS';
      case 'ED005':
        return '3 DIAS';
      case 'ED006':
        return '2 DIAS';
      case 'ED007':
        return '1 DIA';
      default:
        return '';
    }
  }

  const getSolutionName = () => {
    switch (dataFields.solucion) {
      case 'SOL001': 
        return 'HOGAR';
      case 'SOL002':
        return 'PES';
      case 'SOL003':
        return 'RC';
      case 'SOL004':
        return 'ALA AZUL';
      case 'SOL005':
        return 'ALA AZUL PLUS';
      case 'SOL006':
        return 'AUTOS';
      case 'SOL007':
        return 'PLAN VIVE';
      default:
        return '';
    }
  }

  const formContextValue = {
    handle: {
      setDataFields,
      reset,
      handleInputChange,
      handleSelectChange,
      handleInputDate,
      consultHistorical,
      consultarPoliza,
      setSelectedProduct,
      setHaveSubProducts,
      consultReportUAT,
      setLoadingData,
      setColumnsInfo,
      mergeResults,
      setUserForSearching,
      setConsultStats,
      setLoadingDownload,
      setShowTable,
      setShowAlert,
      setAllowedUsers,
      setDisabledFieldsEstimateForm,
      setOptions,
      customRequest,
      setRedirectValue,
      handleRadioChange,
      setCallCenterConsult,
      setReportServiceObj,
      setDisabledQuotationButton,
      setDataSource
    },
    value: {
      dataFields,
      fechas,
      disabledButtons,
      dataSource,
      loadingData,
      selectedProduct,
      haveSubProducts,
      columnsInfo,
      userForSearching,
      consultStats,
      loadingDownload,
      showTable,
      showAlert,
      allowedUsers,
      redirectUriCotizador,
      disabledFieldsEstimateForm,
      options,
      redirectValue,
      callCenterConsult,
      reportServiceObj,
      disabledQuotationButton,
      setDataSource
    }
  }

  return { formContextValue };

};