import { useState, useCallback, useEffect } from 'react'

// @utils
import { client } from 'utils/prismic-helpers'

export default function usePrismicQuerySingle(customType) {
  const [loading, setLoading] = useState(true)
  const [info, setInfo] = useState({
    page: null
  })

  const getInfo = useCallback(async() => {
    try {
      const data = await client.getSingle(customType)

      setInfo({
        page: data.data
      })
      setLoading(false)
    } catch (error) {
      console.warn('Error getting info', error)
    }
  }, [])

  useEffect(() => {
    getInfo()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { info, loading }
}
