import Image from 'components/news/detail/image'
import Text from 'components/news/detail/text'
import Video from 'components/news/detail/video'
import React from 'react'

const Slice = ({ slices }) => (
    slices.map((slice, k) => {
        switch (slice.slice_type) {
            case 'texto':
                return <Text data={slice} index={k} key={k} />
            case 'imagen':
                return <Image data={slice} index={k} key={k} />
            case 'video_youtube':
            case 'video_vimeo':
                return <Video data={slice} index={k} key={k} />

            default:
                return null
        }
    })

)

export default Slice;