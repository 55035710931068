import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Collapse } from 'antd';
import './styles.scss'

const { Panel } = Collapse;

const Faqs = ({ info }) => {
    return (
        <section className="sec__default sec__default-faqs c-gr">
            <div className="container">
                <h2 className="title__ppal title__ppal--center title__ppal--sm title__ppal--dr title__ppal--line title__ppal--line-c-3 title__ppal--extra-bold">
                    <strong>
                        {RichText.asText(info.primary.title)}
                    </strong>
                </h2>
                <Collapse defaultActiveKey={['0']} className="collapse__faqs">
                    {info.items.map((item, k) => (
                        <Panel header={RichText.asText(item.question)} id={`seguro-detalle-faqs-${k}`} key={k}>
                            <RichText render={item.answer} />
                        </Panel>
                    ))}
                </Collapse>
            </div>
        </section>
    )
}

export default Faqs