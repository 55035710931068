// @vendors
import React, { useContext, useState } from 'react';
import { Modal, Form, Input, Button, Collapse } from 'antd';
import jwt_decode from "jwt-decode";
// @context
import { PortalAgentesContext } from 'context/context';
// @utils
import { Microservice } from "services/microservice";
import { handlePreventKeys } from 'utils/utils';
// @components
import { apiGraph } from 'authConfig';
import ModalMessage from "components/modal-message";
import Loading from 'components/loading';
// @styles
import './styles.scss'
import { useMsal } from '@azure/msal-react';

export default function ModalEditProfile({ visible, onClose }) {
    const { value: { user } } = useContext(PortalAgentesContext);
    const [editInfo, setEditInfo] = useState({});
    const [showModalMessage, setShowModalMessage] = useState(false);
	const [messageModal, setMessageModal] = useState('');
    const [loading, setLoading] = useState(false);
    const { instance, accounts } = useMsal();

    const handleInputChange = (event) => {
        const value = event.target.value;
        setEditInfo({ ...editInfo, [event.target.name]: value });
    }

    const updateProfileInfo = async () => {
        setLoading(true);
        var decode = jwt_decode(user.idToken);
        const body = {
            method: "patch",
            url: `${apiGraph.url}/${decode.oid}`,
            body: editInfo
        }
        try {
            const res = await Microservice(body, user.idToken, 'graph', instance, accounts);
            if (res !== undefined) {
                console.log(res);
                const msg = 'Se ha actualizado correctamente la información.';
                openModalMessage(msg);
                setLoading(false);
            } else {
                const err = 'Lo sentimos. No se ha podido actualizar la información.';    
                openModalMessage(err);
                setLoading(false);
            }
        } catch (error) {
            const err = 'Lo sentimos. No se ha podido actualizar la información.';
            console.log(error);
            openModalMessage(err); 
            setLoading(false);
        }
    }

    const openModalMessage = (msg) => {
		setMessageModal(msg);
		setShowModalMessage(true);
	}

	const closeModalEstimate = () => {
		setShowModalMessage(false);
		setMessageModal('');
	}

    return (
        <>
            <Modal
                title={
                    <h3 className="title__ppal title__ppal--sm title__ppal--c-dr title__ppal--line title__ppal--extra-bold title__ppal--line-c-2 title__ppal--center title__ppal--column">
                        <span>Editar</span>
                        <strong>Perfil</strong>
                    </h3>
                }
                wrapClassName="modal__default modal__default-flex"
                width="800px"
                footer={null}
                visible={visible}
                keyboard={!loading}
                onCancel={onClose}
                maskClosable={!loading}
            >
                {loading ? <Loading /> : 
                    <>
                        <Form layout="vertical">
                            <Form.Item style={{ marginBottom: '40px' }}>
                                <Input
                                    type="text"
                                    placeholder=" "
                                    id='displayName'
                                    name='displayName'
                                    value={editInfo['displayName'] ? editInfo['displayName'] : user.userInfo.displayName}
                                    onChange={(e) => handleInputChange(e)}
                                    onKeyPress={(e) => handlePreventKeys(e, 'text')}
                                />
                                <label className="ant-label">
                                    Nombre completo
                                </label>
                            </Form.Item>
                            <Form.Item>
                                <Input
                                    type="text"
                                    placeholder=" "
                                    disabled
                                    id='mail'
                                    name='mail'
                                    value={user.email}
                                />
                                <label className="ant-label">
                                    Correo
                                </label>
                            </Form.Item>
                            <Form.Item>
                                <Input
                                    type="text"
                                    placeholder=" "
                                    id='state'
                                    name='state'
                                    value={editInfo['state'] ? editInfo['state'] : user.userInfo.state}
                                    onChange={(e) => handleInputChange(e)}
                                    onKeyPress={(e) => handlePreventKeys(e, 'text')}
                                />
                                <label className="ant-label">
                                    Estado o provincia
                                </label>
                            </Form.Item>
                            <Form.Item>
                                <Input
                                    type="text"
                                    id='input-text-profile-name'
                                    placeholder=" "
                                    id='city'
                                    name='city'
                                    value={editInfo['city'] ? editInfo['city'] : user.userInfo.city}
                                    onChange={(e) => handleInputChange(e)}
                                    onKeyPress={(e) => handlePreventKeys(e, 'text')}
                                />
                                <label className="ant-label">
                                    Ciudad
                                </label>
                            </Form.Item>
                            <Form.Item>
                                <Input
                                    type="text"
                                    id='input-text-profile-name'
                                    placeholder=" "
                                    id='streetAddress'
                                    name='streetAddress'
                                    value={editInfo['streetAddress'] ? editInfo['streetAddress'] : user.userInfo.streetAddress}
                                    onChange={(e) => handleInputChange(e)}
                                    onKeyPress={(e) => handlePreventKeys(e, 'text')}
                                />
                                <label className="ant-label">
                                    Dirección
                                </label>
                            </Form.Item>
                            <Form.Item>
                                <Input
                                    type="text"
                                    id='input-text-profile-name'
                                    placeholder=" "
                                    id={`extension_${apiGraph.extendedAttributesClientId}_RFC`}
                                    name={`extension_${apiGraph.extendedAttributesClientId}_RFC`}
                                    value={editInfo[`extension_${apiGraph.extendedAttributesClientId}_RFC`] ? 
                                        editInfo[`extension_${apiGraph.extendedAttributesClientId}_RFC`] 
                                        : user.userInfo[`extension_${apiGraph.extendedAttributesClientId}_RFC`]
                                    }
                                    onChange={(e) => handleInputChange(e)}
                                    onKeyPress={(e) => handlePreventKeys(e, 'text')}
                                />
                                <label className="ant-label">
                                    RFC
                                </label>
                            </Form.Item>
                            <Form.Item>
                                <Input
                                    type="text"
                                    id='input-text-profile-name'
                                    placeholder=" "
                                    id={`extension_${apiGraph.extendedAttributesClientId}_Celular`}
                                    name={`extension_${apiGraph.extendedAttributesClientId}_Celular`}
                                    value={editInfo[`extension_${apiGraph.extendedAttributesClientId}_Celular`] ? 
                                        editInfo[`extension_${apiGraph.extendedAttributesClientId}_Celular`]
                                        : user.userInfo[`extension_${apiGraph.extendedAttributesClientId}_Celular`]
                                    }
                                    onChange={(e) => handleInputChange(e)}
                                    onKeyPress={(e) => handlePreventKeys(e, 'number')}
                                />
                                <label className="ant-label">
                                    Celular
                                </label>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    data-testid="btnEditar"
                                    className="solid c-2"
                                    style={{ margin: '0 auto' }}
                                    onClick={() => updateProfileInfo()}
                                    disabled={loading}
                                    role="button"
                                >
                                    Editar
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className='panel__aditional'>
                            <h3 className='panel__aditional-title'>
                                Usuarios asignados
                            </h3>
                            <Collapse accordion className='accordion__users'>
                                {user.assignedUsers &&
                                    user.assignedUsers.map((item, index) => (
                                        <Collapse.Panel
                                            header={`${item.nombreUsuario} (${item.cdUsuario})`}
                                            key={`collapse-users-profile-${index}`}
                                        >
                                            {/* <div className="loading-upper"><Loading /></div> */}
                                            <div className='accordion__users-content'>
                                                <h2 className='accordion__users-title'>
                                                    Puntos de venta
                                                </h2>
                                                <ul className='accordion__users-points'>
                                                    {item.puntosDeVenta?.map((ptoVenta, k) => (
                                                        <li key={`${ptoVenta.cdPuntoVenta}-${k}`}>
                                                            <strong>Código</strong> {ptoVenta.cdPuntoVenta}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Collapse.Panel>
                                    ))}
                            </Collapse>
                        </div>
                    </>
                }
            </Modal>
            <ModalMessage 
				open={showModalMessage} 
				closeModal={closeModalEstimate} 
				message={messageModal}
			/>
        </>
    )
}