// @vendors
import React, { useContext, useMemo } from "react";
import jwt from "jwt-encode";
import { v4 as uuidv4 } from "uuid";
// import uuid from "uuid";
import { Helmet } from "react-helmet";
import { Card } from "antd";

// @constants
import { HOMEPAGE } from "constants/site";

// @hooks
import usePrismicQuerySingle from "hooks/usePrismicQuerySingle";

// @components
import Layout from "layout";
import Loading from "components/loading";

// @styles
import "./styles.scss";
import DotsCSS from "components/dots-css";
import { myReports } from "authConfig";
import { Tableu } from "components/tableu";
import { PortalAgentesContext } from "context/context";
import ErrorPage from "pages/errors";

const tableauStatus = {
  notLoggedIn: "not-logged-in",
  loggedIn: "logged-in",
  error: "error",
};
export default function MyReports() {
  const { info, loading } = usePrismicQuerySingle("pagina_de_reportes");
  const [tableaucurrentStatus, setTableauStatus] = React.useState(tableauStatus.notLoggedIn);
  const {
    value: { user },
  } = useContext(PortalAgentesContext);

  const page = useMemo(() => info.page, [info]);

  const { title, url } = React.useMemo(() => {
    const title = page?.title?.[0]?.text ?? "";
    const url = page?.url?.url ?? "";
    return { title, url };
  }, [page]);

  const token = React.useMemo(() => {
    const email = user?.email ?? "";
    const expDate = new Date();
    expDate.setMinutes(expDate.getMinutes() + 10);
    const exp = expDate.getTime() / 1000;

    const payload = {
      exp: exp,
      aud: "tableau",
      jti: uuidv4(),
      sub: email,
      scp: ["tableau:views:embed"],
    };
    const token = jwt(payload, myReports.clientSecretKey, {
      kid: myReports.clientSecretId,
      iss: myReports.clientId,
      alg: "HS256",
    });

    return token;
  }, []); // eslint-disable-line

  React.useEffect(() => {
    const checkSingin = async () => {
      const signUrl =
        "https://us-west-2b.online.tableau.com/vizportal/api/web/v1/auth/embed/signin";

      const response = await fetch(signUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ jwt: token, siteName: "suraagentes" }),
      });

      const data = await response.json();
      return data;
    };

    checkSingin()
      .then((data) => {
        if (data?.result?.errors) setTableauStatus(tableauStatus.error);
        else setTableauStatus(tableauStatus.loggedIn);
      })
      .catch(() => setTableauStatus(tableauStatus.error));
  }, [url, token]); // eslint-disable-line
  if (loading || tableaucurrentStatus === tableauStatus.notLoggedIn) return <Loading />;

  if (tableaucurrentStatus === tableauStatus.error) return <ErrorPage type="noPermissions" />;

  return (
    <>
      <Helmet>
        <title>{HOMEPAGE.title}</title>
      </Helmet>
      <Layout>
        <section className="sec__default custom_section">
          <DotsCSS type="solid solid--r-t" color="c-gr" />
          <DotsCSS type="solid solid--l-b" color="c-gr" />
          <div className="container">
            <h1 className="title__ppal title__ppal--md title__ppal--c-1 title__ppal--extra-bold title__ppal--line title__ppal--line-c-3 title__ppal--center">
              <strong>{title}</strong>
            </h1>
          </div>
        </section>
        <section className="sec__default c-gr">
          <Card className="card__default card__default--shadow my_reports_card">
            {tableaucurrentStatus === tableauStatus.loggedIn && <Tableu token={token} src={url} />}
          </Card>
        </section>
      </Layout>
    </>
  );
}
