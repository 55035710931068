// @vendors
import React, { useMemo } from 'react'
import { RichText } from 'prismic-reactjs'
import { Helmet } from 'react-helmet'

// @constants
import { HOMEPAGE } from 'constants/site'

// @hooks
import usePrismicQuerySingle from 'hooks/usePrismicQuerySingle';
import usePrismicQueryByTags from 'hooks/usePrismicQueryByTags';

// @components
import Layout from 'layout'
import DotsCSS from 'components/dots-css'
import List from 'components/news/list'
import Loading from 'components/loading';

const News = () => {
	const { info: infoNews, loading: loadingNews } = usePrismicQueryByTags('new_post', [])
	const { info, loading } = usePrismicQuerySingle('news');


	const page = useMemo(() => info.page, [info])
	const newsList = useMemo(() => infoNews, [infoNews])

	return (
		<>
			<Helmet>
				<title>Novedades - {HOMEPAGE.title}</title>
			</Helmet>
			<Layout>
				{loading || loadingNews
					? <Loading />
					: (
						<>
							<section className="sec__default">
								<DotsCSS type="solid solid--r-t" color="c-gr" />
								<DotsCSS type="solid solid--l-b" color="c-gr" />
								<div className="container">
									<h1 className="title__ppal title__ppal--md title__ppal--c-1 title__ppal--extra-bold title__ppal--line title__ppal--line-c-3 title__ppal--center">
										<strong>{RichText.asText(page.section_title)}</strong>
									</h1>
								</div>
							</section>
							<section className="sec__default c-gr">
								<div className="container">
									<List items={newsList} />
								</div>
							</section>
						</>
					)}
			</Layout>
		</>
	)
}

export default News;