import React, {createRef, useContext, useEffect, useState} from "react";
import {Sidetab} from "@typeform/embed-react";
import {serviceUrlInsuranceAgents, typeFormId} from "../../authConfig";
import {Microservice} from "../../services/microservice";
import {useMsal} from "@azure/msal-react";
import {PortalAgentesContext} from "context/context";


function findUsers(usuarios) {

  if (usuarios && Array.isArray(usuarios) && usuarios.length) {

    const mxUsers = usuarios.filter(user => user.cdUsuario.toUpperCase().indexOf("MX") >= 0);

    if (mxUsers && Array.isArray(mxUsers) && mxUsers.length) {
      return mxUsers;
    } else {
      return usuarios;
    }

  } else {
    return []
  }
}

export const TypeForm = ({userProfile}) => {


  const {instance, accounts} = useMsal();

  const {
    value: {user},
  } = useContext(PortalAgentesContext);

  const [insuranceAgents, setInsuranceAgents] = useState(null);
  const [insuranceAgentsUser, setInsuranceAgentsUser] = useState(null);
  const [insuranceAgentsName, setInsuranceAgentsName] = useState(null);
  const [printTypeform, setPrintTypeform] = useState(false);

  const {nombreUsuario, username, usuarios} = userProfile

  const mxUsers = findUsers(usuarios)


  if (!typeFormId) {
    console.error('Id de typeform no definido')
  }


  const getOptionsAgent = async () => {


    if (mxUsers && Array.isArray(mxUsers) && mxUsers.length) {

      let index = 0;
      let found = false;
      while (!found && index < mxUsers.length) {
        let mxUser = mxUsers[index];

        const sendUrl = serviceUrlInsuranceAgents.replace("{user}", mxUser.cdUsuario);

        const body = {
          method: "get",
          url: sendUrl,
          body: {},
        };
        const res = await Microservice(body, user.idToken, "management", instance, accounts);
        if (res && Array.isArray(res) && res.length) {
          found = true;
          const insuranceAgents = res.map(insuranceAgent => insuranceAgent.key);
          setInsuranceAgents(insuranceAgents.join('-'))
          setInsuranceAgentsUser(mxUser.cdUsuario ?? undefined)

          if (res.length === 1) {
            setInsuranceAgentsName(res[0].description)
          }

          setPrintTypeform(true)
        }

        index++;
      }


    } else {
      setPrintTypeform(true)
      console.error("No existen usuarios para el agente")
    }


  };


  useEffect(() => {
    getOptionsAgent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const sidetabRef = createRef()
  return (
    <>
      {
        printTypeform ?
          <Sidetab
            id={typeFormId}
            ref={sidetabRef}
            medium="demo-test"
            hidden={{
              agente_id: insuranceAgents ?? 'AGENTE_NO_DEFINIDO',
              agente_mail: username,
              alea_user: insuranceAgentsUser ?? 'USUARIO_ALEA_NO_DEFINIDO',
              agente_name: insuranceAgentsName ? insuranceAgentsName : nombreUsuario
            }}
            buttonText="Centro de ayuda"
            buttonColor={"#2D6DF6"}
          /> : undefined
      }
    </>
  )
}