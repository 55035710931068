import * as XLSX from 'xlsx';

const toExportFileName = (excelFileName) => {
  return `${excelFileName}_${new Date().toLocaleDateString()}.xlsx`;
}

const exportAsExcelFile = (json, columnsName, excelFileName) => {
  const worksheet = createWs(json, Object.keys(columnsName), columnsName);
  const workbook = { Sheets: { Data: worksheet }, SheetNames: ['Data'] };
  XLSX.writeFile(workbook, toExportFileName(excelFileName));
}

const createWs = (data, fields, titles) => {
  const ws = XLSX.utils.json_to_sheet(deleteProperties(data, fields), { header: fields });
  const range = XLSX.utils.decode_range(ws['!ref']);
  for (let c = range.s.c; c <= range.e.c; c++) {
    const header = XLSX.utils.encode_col(c) + '1';
    ws[header].v = titles[ws[header].v];
  }
  return ws;
}

const deleteProperties = (data, fields) => {
  const dataDownload = data.map(element => {
    const copyElement = { ...element }
    const props = Object.keys(copyElement);
    props.forEach(prop => {
      // tslint:disable-next-line: no-unused-expression
      if (typeof (copyElement[prop]) === "object") {
        copyElement[prop] = copyElement[prop]?.name || copyElement[prop]?.nombre
      }
      !fields.includes(prop) && delete copyElement[prop];
    });
    return copyElement
  });
  return dataDownload;
}

export { exportAsExcelFile };

