// @vendors
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Card, Col, Row } from "antd";

// @constants
import { HOMEPAGE } from "constants/site";
import { Microservice } from "services/microservice";
import { PortalAgentesContext } from "context/context";

// @hooks
import usePrismicQuerySingle from "hooks/usePrismicQuerySingle";

// @components
import Layout from "layout";
import Loading from "components/loading";

// @styles
import "./styles.scss";
import DotsCSS from "components/dots-css";

const goTo = (link, uid) => {
  if (link === "pagina_de_reportes") return `/mis-reportes/${uid}`;
  if (link === "pagina_de_mis_polizas") return `/polizas/${uid}`;
  return "";
};
export default function ServicesAssistance() {
  const { info, loading } = usePrismicQuerySingle("pagina_de_datos_generales");
  const { uid } = useParams();
  const history = useHistory();
  const [userDetails, setUserDetails] = React.useState({});
  const { instance, accounts } = useMsal();
  const {
    value: { user },
  } = React.useContext(PortalAgentesContext);

  const page = useMemo(() => info.page, [info]);

  const folders = useMemo(() => {
    const folders = page?.body?.[1]?.items ?? [];
    if (!folders.length) return [];
    return folders.map((folder) => ({
      title: folder?.titulo_de_seccion?.[0]?.text,
      link: `${folder?.link?.type ?? ""}`,
    }));
  }, [page, uid]);

  useEffect(() => {
    const getAgentInfo = async () => {
      const agentInfo = page?.body?.[0]?.primary ?? {};
      const url = agentInfo?.url?.url ?? "";
      const sendUrl = url.replace("{userId}", uid);
      const body = {
        method: "get",
        url: sendUrl,
        body: {},
      };
      const res = await Microservice(body, user.idToken, "management", instance, accounts);
      setUserDetails(res?.data[0]);
    };
    if (!loading) getAgentInfo();
  }, [page, uid, loading]);
  if (loading || !Object.keys(userDetails).length) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>{HOMEPAGE.title}</title>
      </Helmet>
      <Layout>
        <section className="sec__default custom_section">
          <DotsCSS type="solid solid--r-t" color="c-gr" />
          <DotsCSS type="solid solid--l-b" color="c-gr" />
          <div className="container">
            <h1 className="title__ppal title__ppal--md title__ppal--c-1 title__ppal--extra-bold title__ppal--line title__ppal--line-c-3 title__ppal--center title_general_deatils">
              <strong>
                ¡Bienvenido{userDetails?.nombreAgente ? " " + userDetails.nombreAgente : ""}!
              </strong>
            </h1>
          </div>
          <div className="userdetails_container">
            <Card className="userdetails_card">
              <h4 className="userdetails_title">Datos Generales</h4>
              <div className="userdetails_card_text_sections">
                <p className="userdetails_text_label">Nombre del agente</p>
                <p className="userdetails_text_info">
                  {userDetails?.nombreAgente ?? "No disponible"}
                </p>
              </div>
              <div className="userdetails_card_text_sections">
                <p className="userdetails_text_label">Ejecutivo comercial asignado</p>
                <p className="userdetails_text_info">
                  {userDetails?.nombreEjecutivo ?? "No disponible"}
                </p>
              </div>
              <div className="userdetails_card_text_sections">
                <p className="userdetails_text_label">Banco</p>
                <p className="userdetails_text_info">{userDetails?.banco ?? "No disponible"}</p>
              </div>
            </Card>
          </div>
        </section>
        <section className="sec__default c-gr">
          <Row className="container links_sections_container">
            {folders.map((folder, index) => (
              <Col xs={24} sm={12} md={8} lg={5} xl={5} key={index}>
                <Card
                  hoverable
                  className="link_section_container_root"
                  onClick={() => history.push(goTo(folder.link, uid))}
                >
                  <div className="link_section_title_container">
                    <em className="far fa-file" />
                    <h4 className="link_section_title">{folder.title}</h4>
                  </div>

                  <h4 className="link_section_action">VER</h4>
                </Card>
              </Col>
            ))}
          </Row>
        </section>
      </Layout>
    </>
  );
}
