// @vendors
import React, { useEffect, useContext, useState } from 'react'
import { Skeleton } from 'antd'

// @services
import { Microservice } from 'services/microservice';

// @context
import { PortalAgentesContext, PortalAgentesFormContext } from 'context/context';

// @utils
import { formatter } from 'utils/utils'
// @styles
import './styles.scss'
import { useMsal } from '@azure/msal-react';

const Stats = ({ data }) => {
    const { value: { user } } = useContext(PortalAgentesContext);
    const { value: { userForSearching, consultStats } } = useContext(PortalAgentesFormContext);
    const [loading, setLoading] = useState(true)
    const [stats, setStats] = useState(null);
    const [consult, setConsult] = useState(false);
    const title = data.primary.title[0].text
    const api = data.primary.api[0].text
    const { instance, accounts } = useMsal();

    const getStats = async (userSelected) => {
        const body = {
            method: 'post',
            url: `${api}`,
            body: {
                userId: userSelected
            }
        }
        const res = await Microservice(body, user.idToken, 'services', instance, accounts);
        setStats(res);
        setLoading(false);
    };

    useEffect(() => {
        if (user.idToken.length > 0 && !consult) {
            getStats(user.assignedUsers[0].cdUsuario);
            setConsult(true);
        }
        if (consultStats) {
            setLoading(true);
            getStats(userForSearching);
        }
    }, [user.idToken, userForSearching, consultStats]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='stats'>
            <div className='stats-header'>
                {title} en los últimos 12 meses
            </div>
            <div className='stats-body'>
                <div className='stats-body-item-20'>
                    <div className="item">
                        <em className='fal fa-money-bill' />
                        <div className="item-title">
                            Comisión generada
                        </div>
                        {loading && <Skeleton active title={null} paragraph={{ rows: 1 }} />}
                        {!loading && (
                            <div className='item-value item-value-cop' id='comision-generada-value'>
                               <strong>{formatter.format(stats && stats.comisionGenerada)}</strong>
                            </div>
                        )}
                    </div>
                </div>
                <div className='stats-body-item-20'>
                    <div className="item">
                        <em className='fal fa-gift' />
                        <div className="item-title">
                            Total Primas Emitidas
                        </div>
                        {loading && <Skeleton active title={null} paragraph={{ rows: 1 }} />}
                        {!loading && (
                            <div className='item-value item-value-cop' id='total-primas-emitidas-value'>
                               <strong>{formatter.format(stats && stats.totalPrimasEmitidas)}</strong>
                            </div>
                        )}
                    </div>
                </div>
                <div className='stats-body-item-20'>
                    <div className="item">
                        <em className='fal fa-tags' />
                        <div className="item-title">
                            Total de ventas
                        </div>
                        {loading && <Skeleton active title={null} paragraph={{ rows: 1 }} />}
                        {!loading && (
                            <div className='item-value' id='total-de-ventas-value'>
                               <strong>{stats && stats.totalVentas}</strong>
                            </div>
                        )}
                    </div>
                </div>
                <div className='stats-body-item-20'>
                    <div className="item">
                        <em className='fal fa-calendar-check' />
                        <div className="item-title">
                            Mes de mejores ventas
                        </div>
                        {loading && <Skeleton active title={null} paragraph={{ rows: 1 }} />}
                        {!loading && (
                            <div className='item-value' id='mes-mejor-venta-value'>
                               <strong>{stats && stats.mejoresVentas}</strong>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stats