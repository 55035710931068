// @vendors
import React, { useMemo } from 'react'
import { RichText } from 'prismic-reactjs'
import { Helmet } from 'react-helmet'

// @constants
import { HOMEPAGE } from 'constants/site'

// @hooks
import usePrismicQuerySingle from 'hooks/usePrismicQuerySingle'

// @components
import Layout from 'layout'
import DotsCSS from 'components/dots-css'
import Loading from 'components/loading'
import AllServices from 'components/all-services'

const Services = () => {
	const { info, loading } = usePrismicQuerySingle('services')

	const page = useMemo(() => info.page, [info])
	

	return (
		<>
			<Helmet>
				<title>Servicios - {HOMEPAGE.title}</title>
			</Helmet>
			<Layout>
				{loading
					? <Loading />
					: (
						<>
							<section className="sec__default">
								<DotsCSS type="solid solid--r-t" color="c-gr" />
								<DotsCSS type="solid solid--l-b" color="c-gr" />
								<div className="container">
									<h1 className="title__ppal title__ppal--md title__ppal--c-1 title__ppal--extra-bold title__ppal--line title__ppal--line-c-3 title__ppal--center">
										<strong>{RichText.asText(page.section_title)}</strong>
									</h1>
								</div>
							</section>
							<section className="sec__default c-gr">
								<div className="container">
									<AllServices items={page.body} />
								</div>
							</section>
						</>
					)}
			</Layout>
		</>
	)
};

export default Services;