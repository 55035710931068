import React, { useMemo, useContext } from "react";
import { RichText } from "prismic-reactjs";
import { Helmet } from "react-helmet";
import { PortalAgentesContext } from "context/context";

// @components
import { Layout } from "antd";
import DotsCSS from "components/dots-css";
import Slice from "./slice";

// @constants
import { HOMEPAGE } from "constants/site";
import ErrorPage from "pages/errors";

// @hooks
import usePrismicQuerySingle from "hooks/usePrismicQuerySingle";

// @styles
import "./styles.scss";
import Loading from "components/loading";

export default function MyPolices() {
  const { info, loading } = usePrismicQuerySingle("pagina_de_mis_polizas");
  const page = useMemo(() => info.page, [info]);
  if (loading) return <Loading />;
  console.log(RichText.asText(page.service[0].url))
  console.log(page, "my polices");
  return (
    <>
      <Helmet>
        <title>{HOMEPAGE.title}</title>
      </Helmet>
      <Layout>
        <section className="sec__default">
          <DotsCSS type="solid solid--r-t" color="c-gr" />
          <DotsCSS type="solid solid--l-b" color="c-gr" />
          <div className="container">
            <h1 className="title__ppal title__ppal--md title__ppal--c-1 title__ppal--extra-bold title__ppal--line title__ppal--line-c-3 title__ppal--center">
              <strong>{RichText.asText(page.section_title)}</strong>
            </h1>
          </div>
        </section>
        <section className="sec__default c-gr sec__default-top--over">
          <div className="container">
            <Slice
              slices={page.body}
              urlService={RichText.asText(page.service[0].url)}
              reportService={
                page?.report_service?.length ? RichText.asText(page.report_service[0].url) : ""
              }
              redirectUri={page?.redirect_uri?.length ? RichText.asText(page.redirect_uri) : ""}
            />
          </div>
        </section>
      </Layout>
    </>
  );
}
