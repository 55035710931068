import React from 'react'
import FieldText from 'components/historical/form/field-text'
import FieldSelect from 'components/historical/form/field-select'
import FieldDate from 'components/historical/form/field-date'

const Slice = ({ fields }) => (
  fields.map((field, k) => {
    switch (field.type) {
      case 'Campo alfanumérico':
      case 'Campo solo texto':
      case 'Campo numérico':
        return <FieldText data={field} key={k} />
      case 'Fecha desde':
      case 'Fecha hasta':
        return <FieldDate data={field} key={k} />
      case 'Selección única':
        return <FieldSelect data={field} key={k} />
      default:
        return null
    }
  })
)

export default Slice