import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import { client } from 'utils/prismic-helpers'
import { Predicates } from 'prismic-javascript'
import FormDinamic from './form'
import Empty from 'components/empty'

const Estimate = ({ open, closeModal, name }) => {
	const [prismicData, setPrismicData] = useState({ form: null });
	const [notFound, toggleNotFound] = useState(false);

	const fetchPrismicData = async () => {
		try {
			const form = await client.query([
				Predicates.at('document.type', 'form_estimate'),
				Predicates.at('my.form_estimate.key', name)
			]);
			if (form) {
				setPrismicData({form: form.results})
			} else {
				toggleNotFound(true)
			}
		} catch (error) {
			toggleNotFound(true)
		}
	}

	useEffect(() => {
		if (!open) {
			setPrismicData({form: null})
		}
		if (name && open) {
			fetchPrismicData();
		}
	}, [open, name])

	if (prismicData.form) {
		const form = prismicData.form;
		return form.length ? (
			<Modal 
				visible={open} 
				onCancel={closeModal} 
				data-testid="modalestimate"
				wrapClassName="modal__default" 
				width="600px" 
				footer=""
				title={
					<h3 className="title__ppal title__ppal--sm title__ppal--c-dr title__ppal--line title__ppal--extra-bold title__ppal--line-c-2 title__ppal--center title__ppal--column">
						<span>Iniciar</span>
						<strong>Cotización</strong>
					</h3>
				} 
			>
				<FormDinamic data={form} closeModal={closeModal} />
			</Modal>
		) : (
			<Modal title='' visible={open} onCancel={closeModal} wrapClassName="modal__default modal__default-empty" width="400px" footer="">
				<Empty message='No hay formulario disponible' />
				<Button data-testid="btn-close" className="solid c-3" onClick={closeModal}>
					Cerrar
				</Button>
			</Modal>
		)
	} else if (notFound) {
		return 'Not found'
	}

	return null
}

export default Estimate;