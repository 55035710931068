import React from "react";
import { Button, Modal } from "antd";
import "./styles.scss";

const ModalNotification = ({ open, closeModal, message, title, link }) => {
  const goToLink = () => {
    if (link) window.open(link, "_blank");
    closeModal();
  };
  return (
    <Modal
      title={title ?? ""}
      visible={open}
      onCancel={closeModal}
      wrapClassName="modal__default modal__default-empty"
      className="empty-message root"
      width="400px"
      footer=""
    >
      <div className="empty" style={{ width: "auto" }}>
        <h2 className="empty-message">{message}</h2>
      </div>

      <Button className="outline c-2 link-button" data-testid="btn-close" onClick={goToLink}>
        {link ? "CONOCE" : "ACEPTAR"}
      </Button>
    </Modal>
  );
};

export default ModalNotification;
