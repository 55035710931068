// @vendors
import React, { useMemo } from 'react'
import { RichText } from 'prismic-reactjs'
import { Link } from 'react-router-dom';

// @hooks
import usePrismicQuerySingle from 'hooks/usePrismicQuerySingle';

// @styles
import './styles.scss'

const Footer = () => {
	const { info, loading } = usePrismicQuerySingle('footer')
	const today = new Date();


	const data = useMemo(() => info.page, [info])

	if (!loading) {
		return (
			<footer className="footer">
				<div className="container">
					<div className="footer__top">
						<Link to="/" className="footer__top--logo">
							<img src={data.logo.url} alt={data.logo.alt} />
						</Link>
						<div className="footer__top--quick-links">
							<a className="item" href={`tel: ${RichText.asText(data.faq_doubts)}`} >
								<span>Dudas sobre tus seguros</span>
								<strong>{RichText.asText(data.faq_doubts)}</strong>
							</a>
							<a href={data.privacy_link.url} target='_blank' className="item">
								<span>Política de</span>
								<strong>privacidad de los datos</strong>
							</a>
						</div>
					</div>
					<div className="footer__bottom">
						<div className="footer__bottom--copy">
							© {today.getFullYear()} {RichText.asText(data.copyright)}
						</div>
						<div className="footer__bottom--social">
							{data.social_networks.map((item, k) => (
								<a
									href={item.link.url}
									className="footer__bottom--social-network"
									target="_blank" rel="noopener noreferrer"
									key={`${k}-link`}
									id={`footer-social-${k}`}
								>
									<em className={`fab fa-${RichText.asText(item.icon)}`} />
								</a>
							))}
						</div>
					</div>
				</div>
			</footer>
		)
	} else {
		return null
	}
};

export default Footer;