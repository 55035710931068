import React from 'react'
import Banner from 'components/news/detail/banner'
import { Helmet } from 'react-helmet'
import Slice from './slice'
import './styles.scss'
import { HOMEPAGE } from 'constants/site'
import useByUID from 'hooks/useByUID'

const Post = ({ match }) => {
	const uid = match.params.uid;
	const [prismicData, notFound] = useByUID('detail', 'new_post', uid, { detail: null });
	if (prismicData.detail) {
		const detail = prismicData.detail
		const data = {
			author: detail.data.author,
			title: detail.data.title,
			category: detail.data.category,
			date: detail.data.date,
			image: detail.data.image
		}
		return (
			<>
				<Helmet>
					<title>{detail.data.title} - {HOMEPAGE.title}</title>
				</Helmet>
				<Banner info={data} />
				<section className="sec__default">
					<div className="container">
						<Slice slices={detail.data.body} />
					</div>
				</section>
			</>
		)
	} else if (notFound) {
		return 'Not Found'
	}
	return null
};

export default Post;