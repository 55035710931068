import { useState, useEffect } from 'react';
import { client } from 'utils/prismic-helpers';

const useByUID = (key, configInit, uid, initialState) => {
  const [prismicData, setPrismicData] = useState(initialState);
  const [notFound, toggleNotFound] = useState(false)

  useEffect(() => {
    client.getByUID(configInit, uid).then((data) => {
      if(data) {
        setPrismicData({[key]: data});
      } else {
        console.warn('No se ha encontrado el documento');
        toggleNotFound(true);
      }
    }).catch((error) => {
      console.error(error);
      toggleNotFound(true);
    });
  }, [uid]);

  return [prismicData, notFound];

}

export default useByUID;