const handlePreventKeys = (event, typeValidation) => {
  const pattNum = typeValidation === "number"
    ? new RegExp("^0$|^[1-9]{1}[\\d]{0,}$") : new RegExp("^s*([0-9a-zA-Z ]+)*$");
  if (!pattNum.test(event.key)) {
    event.preventDefault();
    return false;
  }
};

const formatter = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0
});

const getMonthName = (month) => {
  let date = new Date()
  date.setMonth(month);
  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  return months[date.getMonth()];
}


export { handlePreventKeys, formatter, getMonthName };