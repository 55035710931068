// @vendors
import React, { useMemo, useState, useContext, useEffect, useCallback } from 'react'
import { RichText } from 'prismic-reactjs'
import { Link, NavLink } from 'react-router-dom';
import {  Dropdown, Menu } from 'antd';
import { linkResolver } from 'prismic-config';
import { useMsal } from "@azure/msal-react";
import { PortalAgentesContext } from 'context/context';

// @utils
import { replaceAccents } from 'utils/replace-accents'

// @hooks
import usePrismicQuerySingle from 'hooks/usePrismicQuerySingle';

// @styles
import './styles.scss'

import ModalEditProfile from "components/modal-edit-profile";
import { callCenterOpt, accountStatus } from 'authConfig';
import { ModalAgentSelector } from 'components/modal-agent-selector';

function Header() {
	const { instance } = useMsal();
	const {
		handle: { setShowCallCenter, setShowEstadoCuenta, setListUserEstadoCuenta, setProductInfo }, value: { user, showCallCenter, showEstadoCuenta, listUserEstadoCuenta }
	} = useContext(PortalAgentesContext);
	const { info, loading } = usePrismicQuerySingle('header');
	const [openNav, setOpenNav] = useState(false);
	const [openEditProfile, setOpenEditProfile] = useState(false);
	const [openAgentSelector, setOpenAgentSelector] = useState(false);
	const [renderLinks, setRenderLinks] = useState([]);

	const data = useMemo(() => info.page, [info]);

	const signOut = () => {
		localStorage.removeItem("isNotificationDisplayed");
		instance.logoutRedirect();
	};

	useEffect(() => {
		if (user.userProfile && user.userProfile.usuarios) {
			if (user.userProfile.usuarios.length > 0 && listUserEstadoCuenta.length === 0) {
				const usuarios = user.userProfile.usuarios;
				const list = [];
				usuarios.forEach(user => {
					user.modelos.forEach(modelo => {
						if (modelo.cdModelo === accountStatus.cdModelAgentes || modelo.cdModelo === accountStatus.cdModelPromotor) {
							list.push(user);
							setShowEstadoCuenta(true);
						}
						if (modelo.cdModelo === callCenterOpt.cdModel) {
							setShowCallCenter(true);
						}
					});
				});
				setListUserEstadoCuenta(list);
			}
		}
	}, [user.userProfile]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (data && data.menu_links) {
			menuLinks();
		}
	}, [data, showEstadoCuenta, showCallCenter]); // eslint-disable-line react-hooks/exhaustive-deps

	const menuLinks = useCallback(() => {
		if (data && data.menu_links) {
			let copyMenu = [...data.menu_links];
			let copyProductInfo = [...data.product_info];
			copyMenu = showEstadoCuenta ? copyMenu : copyMenu.filter(item => item.label[0].text !== "Estados de cuenta");
			copyMenu = showCallCenter ? copyMenu : copyMenu.filter(item => item.label[0].text !== "Call Center");
			setProductInfo(copyProductInfo);
			setRenderLinks(copyMenu);
		}
	}, [data, showEstadoCuenta, showCallCenter]);
	const menu = (
		<Menu>
			<div className="ant-dropdown-menu-user">
				<div className="info" id='user-last-conection'>
					<span>Última conexión</span>
					{user.lastSing}
				</div>
				<div className="info" id='user-last-associate-office'>
					<span>Oficina asociada</span>
					{user.assignedUsers.length ?
						user.assignedUsers[0].dsOficina
						: null}
				</div>
			</div>
			<Menu.Item
				id='user-edit-profile'
				key="1"
				icon={<em className="fal fa-pencil" />}
				onClick={() => setOpenEditProfile(true)}
				data-testid="edit-user-profile"
			>
				Editar perfil
			</Menu.Item>
			<Menu.Item
				id='user-detail-profile'
				key="1"
				icon={<em className="fal fa-file-invoice" />}
				onClick={() => setOpenAgentSelector(true)}
				data-testid="edit-user-profile"
			>
				Datos Generales
			</Menu.Item>
			<Menu.Item
				id='user-sign-out'
				key="2"
				className='sign-out'
				icon={<em className="fal fa-sign-out-alt" />}
				onClick={() => signOut()}
			>
				Cerrar sesión
			</Menu.Item>
		</Menu>
	);

	if (!loading) {
		return (
			<>
				<header className="header">
					<div className="header__top">
						<Link to="/" className="header__top--logo" id='header-logo'>
							{data.logo && data.logo.url && (
								<img src={data.logo.url} alt={data.logo.alt} />
							)}
						</Link>
						<div className="header__top--solution" id='header-solution'>
							{RichText.asText(data.solution_name)}
						</div>
						<Dropdown
							trigger='click'
							overlay={menu}
							arrow
							placement="bottomRight"
							overlayClassName="dropdown__profile"
						>
							<div
								className="header__top--user"
								id='header-user-dropdown'
								data-testid="dropdown"
							>
								<div className="header__top--user-data">
									<strong>{user.name}</strong>
								</div>
								<div className="header__top--user-photo">
									<em className='fal fa-user' />
								</div>
								<em className="fal fa-chevron-down" />
							</div>
						</Dropdown>
					</div>
					{user.userProfile.success ? (
						<div className="header__bottom">
							<div className="header__bottom--business-line" id='header-business-line'>
								{RichText.asText(data.business_line)}
							</div>
							<nav className={`header__bottom--navigation ${openNav ? 'header__bottom--navigation-open' : ''}`} id='menu-navigation'>
								<div className="header__bottom--hamburger" onClick={() => setOpenNav(!openNav)} data-testid="menu-close" id='nav-close'>
									Cerrar menú <em className="fal fa-times" />
								</div>
								{renderLinks.map((item, k) => (
									<NavLink exact to={linkResolver(item.link)} key={k} id={`pagina-de-${replaceAccents(item.label[0].text)}`}>
										{RichText.asText(item.label)}
									</NavLink>
								))}
							</nav>
							<div className="header__bottom--hamburger" onClick={() => setOpenNav(!openNav)} data-testid="menu-open" id='menu-open'>
								Abrir menú <em className="fal fa-bars" />
							</div>
						</div>
					) : null}
				</header>
				<ModalEditProfile visible={openEditProfile} onClose={() => setOpenEditProfile(false)} />
				<ModalAgentSelector visible={openAgentSelector} onClose={() => setOpenAgentSelector(false)} />
			</>
		);
	} else {
		return null;
	}


}

export default Header;