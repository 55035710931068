import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button, Row, Col } from 'antd'
import { PortalAgentesFormContext } from 'context/context';
import './styles.scss'
import Slice from 'components/historical/form/slice';
import Loading from 'components/loading';
import moment from 'moment';

const FormDinamic = ({ fields, urlService, reportService, disabledSearchButton, isMyPolice=false }) => {
	const {uid} = useParams();
	const {
		handle: { setDataFields, reset, consultHistorical, consultReportUAT, setLoadingData,
			consultarPoliza,
			mergeResults, setConsultStats, setShowTable, setShowAlert, customRequest, setRedirectValue, setCallCenterConsult, setReportServiceObj },
		value: { disabledButtons, loadingData, dataFields, callCenterConsult, reportServiceObj }
	} = useContext(PortalAgentesFormContext);

	useEffect(() => {
		if (reportService.length > 0) {
			const rService = JSON.parse(reportService);
			setReportServiceObj(rService);
		}
	}, [reportService])

	const consultData = async () => {
		setLoadingData(true);
		setShowTable(false);
		setConsultStats(true);
		let resReport = [];
		let resHistorical = [];
		if (urlService.includes('cotizaciones')) {
			if (validateFields()) {
				const report = getReportName();
				// const rService = reportService && reportService.length > 0 ? JSON.parse(reportService) : "";
				const urlReportService = dataFields.solucion && reportServiceObj[dataFields.solucion] ?
				reportServiceObj[dataFields.solucion] : reportServiceObj.default;
				if (urlReportService.url && report.allow && urlReportService.url.indexOf("smart") !== -1) {
					const urlReport = `${urlReportService.url}&code=${report.code}`;
					resReport = await consultReportUAT(urlReport);
				} else if (urlReportService.url) {
					const res = await customRequest({ url: urlReportService.url, method: 'GET', body: {} });
					const documents = res && res.respuesta ? res.respuesta.documents : res && res.data && res.data.response && res.data.response.documents
					const agentData = documents && documents.length > 0 && documents[0].datosAgente;
					setRedirectValue({ agentData });
					resReport = { data: { rows: getNewResReport(documents) } };
				}
			}
		}else if(isMyPolice){
			const generateQueryParams = (dataFields) => {
				let queryParams = '';
				Object.keys(dataFields).forEach((key) => {
					if (dataFields[key] !== null && dataFields[key] !== '') {
						if(key === 'effectiveDate' || key === 'effectiveEndDate'){
							queryParams += `${key}=${moment(dataFields[key]).format('DD/MM/YYYY')}&`;
						}
						else if (key ==='policyNumber'){
							const value = dataFields[key].replace('-', '');
							queryParams += `${key}=${value}&`;
						}
						else queryParams += `${key}=${dataFields[key]}&`;
					}
				});
				const removeLastChar = queryParams.slice(0, -1);
				return removeLastChar;
			}
			const sendUrl = urlService.replace('{id}', uid);
			const url = `${sendUrl}&${generateQueryParams(dataFields)}`;
			 resHistorical = await consultarPoliza(url)
		} else {
			resHistorical = await consultHistorical(urlService);
		}
		mergeResults(resHistorical, resReport);
		setConsultStats(false);
		setLoadingData(false);
		setShowTable(true);
	}

	const getNewResReport = (documents) => {
		let newResReport = [];
		documents && documents.forEach(document => {
			if (document) {
				let newReport = { infoCliente: { nombre: '', telefono: '', email: '' } };
				const cotizacion = document.cotizaciones[document.cotizaciones.length - 1];
				newReport.documentid = cotizacion && ((cotizacion.poliza && cotizacion.poliza.numeroPoliza) || (cotizacion.policy && cotizacion.policy.policyNumber));
				newReport.folio = document.configuracion && document.configuracion.folio;
				const dateValue = cotizacion && cotizacion.fechaCreacion ? cotizacion.fechaCreacion : document.configuracion.fechaCreacion;
				newReport.creation_date = moment(dateValue).format('YYYY-MM-DD HH:mm');
				let componentIndex = document.componentes.findIndex(x => x.key === "nombre" && x.value ?x:x.key==="primerNombreAsegurado");
				newReport.e_datoscontratante_nombre = componentIndex !== -1 ? document.componentes[componentIndex].value ? document.componentes[componentIndex].value : ''  : '';
				newReport.e_datoscontratante_apellidorazonsocial = '';
				newReport.e_datoscontratante_segundoapellido = '';
				newReport.auxve = cotizacion && ((cotizacion.planes && cotizacion.planes[0] && cotizacion.planes[0].valor) || (cotizacion.costs && cotizacion.costs.amount));
				newReport.newResReport = true;
				if (document.componentes && document.componentes.length > 0) {
					document.componentes.forEach(component => {
						switch (component.key) {
							case "nombre":
								newReport.infoCliente.nombre = component.value;
								break;
							case "telefono":
								newReport.infoCliente.telefono = component.value;
								break;
							case "email":
								newReport.infoCliente.email = component.value;
								break;
							default:
								break;
						}
					})
				}
				newReport.infoAgente = document.datosAgente ? document.datosAgente : {name: '', usuarioId: null, email: ''};
				newResReport.push(newReport);
			}
		});
		return newResReport;
	}

	const validateFields = () => {
		if (dataFields.solucion && dataFields.solucion !== null) {
			setShowAlert(false);
			return true;
		} else {
			setShowAlert(true);
			return false;
		}
	}

	const getReportName = () => {
		switch (dataFields.solucion) {
			case 'SOL001':
				return { allow: true, code: 'NTFREPORTHOGAR' };
			case 'SOL002':
				return { allow: true, code: 'NTFREPORTPES' };
			case 'SOL003':
				return { allow: true, code: 'NTFREPORTRC' };
			case 'SOL004':
				return { allow: true, code: 'NTFREPORTGM' };
			case 'SOL005':
				return { allow: true, code: 'NTFREPORTGMP' };
			case 'SOL006':
				return { allow: true, code: 'NTFREPORTAUTOS' };
			default:
				return { allow: false };
		}
	}

	useEffect(() => {
		if (!disabledSearchButton && !callCenterConsult) {
			setCallCenterConsult(true);
		}
	}, []);

	useEffect(() => {
		if (fields.length) {
			const data = {};
			fields.forEach(field => {
				data[field.key] = null;
			});
			setDataFields(data);
		}
	}, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="form__historical">
			{loadingData ? <div className="loading-upper"><Loading /> </div> : null}
			<Form layout='vertical'>
				<Row gutter={[15,7]}>
					<>
						<Slice fields={fields} />
						<Col span={24} xs={24} sm={12} md={8} className="form-actions">
							<Button className="outline c-2" htmlType="button" data-testid="reset" onClick={reset} disabled={!disabledSearchButton ? false : disabledButtons}>
								Restablecer
							</Button>
							<Button type="primary" className="solid c-2" data-testid="search" disabled={!disabledSearchButton ? false : disabledButtons} onClick={() => consultData()}>
								Buscar
							</Button>
						</Col>
					</>
				</Row>
			</Form>
		</div>
	)
};

export default FormDinamic